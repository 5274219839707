import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store/store";
import { CONSTANT } from "../constants/constants";

const SERVER_URL = CONSTANT.path.host;

export interface IActivityFrame {
  id: number;
  sessionId: string;
  userId: number;
  startTime: string;
  endTime: string;
  numberOfRequests: number;
  inactivityThreshold: number;
}

export interface ISession {
  id: number;
  sessionId: string;
  isActive: number;
  startTime: string;
  duration: number;
  numberOfRequests: number;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    id: number;
  };
  activityFrames: IActivityFrame[];
}

export interface IActiveUsersResponse {
  currentActiveUsers: number;
  pastActiveUsers: number;
  totalUsersChange: number;
}

export const monitoringApi = createApi({
  reducerPath: "sessionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${SERVER_URL}/monitoring`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).users.loggedUser.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Session"],
  endpoints: (builder) => ({
    getSessions: builder.query<ISession[], void>({
      query: () => "sessions",
      providesTags: ["Session"],
    }),
    getActiveUsers: builder.query<IActiveUsersResponse, number>({
      query: (daysPeriod) => `activeUsers/${daysPeriod}`,
    }),
  }),
});

export const { useGetSessionsQuery, useGetActiveUsersQuery } = monitoringApi;
