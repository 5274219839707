import { createAsyncThunk } from "@reduxjs/toolkit";
import auth from "../../../auth/auth-helper";
import { CONSTANT } from "../../../constants/constants";
const host = CONSTANT.path.host;

export const getVisitorRecognitionStats = createAsyncThunk(
  "advancedReport/getVisitorRecognitionStats",
  async (report, { rejectWithValue, dispatch, getState }) => {
    let { signal, token } = report;
    let currentState = getState();

    let jwt = auth.isAuthenticated();
    let id = jwt.data.user.id;

    let target = "vr_stats";
    let pidParam = "";
    let customDateParam = "";
    let timeParam = "";
    let rt = report.timeGrouping;
    let dgm = report.dgm;
    let dgmParam = `&dgm=${dgm}`;
    let customDateRange = report?.customDateRange;

    if (auth.isAdminRole()) {
      if (!Boolean(currentState.role.partnersSelected)) {
        return false;
      }

      target = "vr_stats_observe";
      pidParam = `&pid=${currentState.role.partnersSelected.map((p) => p.dummy_id).join(",")}`;
    }

    if (rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
      customDateParam = `&cstm_start=${customDateRange.customDateStart}&cstm_end=${customDateRange.customDateEnd}`;
    }

    //apply time selection
    // if (rt === CONSTANT.DATE_GROUP_MODES.HOUR) { //FIXME need to compare with date_grouping_mode
    //   timeParam = `&h=${currentState.advancedReport.timeSelection}`;
    // }

    try {
      let response = await fetch(
        `${host}/statistics/${target}/${id}?rt=${rt}${pidParam}${customDateParam}${timeParam}${dgmParam}`,
        {
          method: "GET",
          headers: {
            authorization: "Bearer " + token,
          },
          signal,
        }
      );

      if (!response.ok) {
        throw new Error("server can not get report");
      }

      let result = await response.json();

      let data = {
        result,
        activeRangeDate: rt,
      };
      // if ("customDateRange" in report) data.customDateRange = report.customDateRange;

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getVisitorRecognitionStatsType = (report = {}) => getVisitorRecognitionStats(report);
