import {
  setOfReturnedReportValues,
  setOfTemplateProperties,
  setOfCalculatedProperties,
  setOfAdditionalCalculations,
  setOfCalculationsToInfoBoxLineChart,
  setOfAdditionalCalculationsToInfoBoxLineChart,
} from "./report-helpers";
import {
  getDateAgo,
  sumObjectsByKey,
  getPrevMonthDates,
  getUniqPairsOfCurrencyAndPct,
  prepareDateFormat,
  prepareDateFormatUTC,
} from "../utils";
import { CONSTANT } from "../constants/constants";
import { getAllTimeFirstDate } from "../summary/utils";

const uniqs = (obj, arr) => {
  let key = arr;
  if (arr.length === 0) key = Object.keys(obj[0]);

  let map = new Map(); // map of uniqs

  for (let i of key) {
    // keys names
    // filling Set (by category)
    let uniqData = new Set();
    for (let j = 0; j < obj.length; j++) {
      uniqData.add(obj[j][i]);
      map.set(i, uniqData); // i - key name,  uniqData - uniq set
    }
  }

  return map;
};

function getDateArrayWithCategory(start, end, category) {
  let arr = [];
  let startDate = new Date(start);
  let endDate = new Date(end);

  let template = {};
  for (let i of category) {
    template[i] = 0;
  }

  let dt = new Date(Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate()));

  while (dt <= endDate) {
    arr.push({
      ...template,
      date: dt.toISOString(),
      dateAsStr: prepareDateFormatUTC(dt),
    });
    dt.setUTCDate(dt.getUTCDate() + 1);
  }

  return arr;
}

function getDateArrayWithCategoryGroupedMonthly(start, endDate, category) {
  let arr = [];
  let template = {};

  // Initialize template with categories
  for (let i of category) {
    template[i] = 0;
  }

  let dt = new Date(start);
  let end = new Date(endDate);

  let monthsArray = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

  while (dt <= end) {
    let newEntry = JSON.parse(JSON.stringify(template)); // Deep clone the template to ensure unique objects
    let dateStr = dt.toString();
    newEntry.date = dateStr;
    newEntry.dateAsStr = prepareDateFormat(dt); // Assuming prepareDateFormat() is defined elsewhere
    newEntry.nameMonth = monthsArray[dt.getMonth()];
    arr.push(newEntry);

    // Move to the next month
    dt.setMonth(dt.getMonth() + 1);

    // Reset the date to the first to avoid issues when current month has more days than the next month
    dt.setDate(1);
  }

  return arr;
}

function getDateArrayWithCategoryAggregated(start, endDate, category) {
  let template = {};
  let dt = new Date(endDate);
  // Initialize template with categories
  for (let i of category) {
    template[i] = 0;
  }
  template.dateAsStr = prepareDateFormat(dt);
  template.date = dt.toString();
  return [template];
}

function getQuarter(month) {
  if (month <= 2) return "Q1";
  if (month <= 5) return "Q2";
  if (month <= 8) return "Q3";
  return "Q4"; // Covers months 9-11
}

function getDateArrayWithCategoryGroupedQuarterly(start, endDate, category) {
  let arr = [];
  let template = {};

  // Initialize template with category values set to 0
  for (let i of category) {
    template[i] = 0;
  }

  let dt = new Date(start);
  const end = new Date(endDate);
  const quartersArray = ["Q1", "Q2", "Q3", "Q4"];

  // Function to determine the quarter for a given date

  while (dt <= end) {
    let quarter = getQuarter(dt.getMonth());
    // Adjusting the prepareDateFormat to handle local date correctly is assumed
    let dateAsStr = prepareDateFormat(dt); // Use the provided function for dateAsStr adjusted for local time

    // Create a local date string to avoid timezone issues
    let localDate = new Date(dt.getTime() - dt.getTimezoneOffset() * 60000).toISOString().split("T")[0];

    arr.push({
      ...template,
      date: localDate,
      dateAsStr: dateAsStr,
      nameQuarter: quarter,
    });

    dt.setUTCDate(1); // Use UTC methods to avoid local timezone affecting the calculation
    dt.setUTCMonth(dt.getUTCMonth() + 3);

    // If the new date exceeds the end date, adjust the loop to avoid extra entries
    if (dt > end) break;
  }
  return arr;
}

function generateTemplateLineChartWidget(start, end) {
  let arr = [];
  let dt = new Date(start);
  let endDate = new Date(end);

  while (dt.toISOString().split("T")[0] <= endDate.toISOString().split("T")[0]) {
    arr.push({
      date: dt.toISOString(),
      dateAsStr: prepareDateFormatUTC(dt), // Assuming this function exists
      value: 0,
      revenue: 0,
      revenueLift: 0,
      impressions: 0,
      fillRate: 0,
      cpm: 0,
    });
    dt.setUTCDate(dt.getUTCDate() + 1);
  }

  return arr;
}

function generateTemplateLineChartWidgetGroupedMonthly(start, endDate) {
  let arr = [];
  let dt = new Date(start);
  let end = new Date(endDate);

  let monthsArray = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

  while (dt <= end) {
    let monthIndex = dt.getUTCMonth();
    arr.push({
      date: dt.toISOString(),
      dateAsStr: prepareDateFormatUTC(dt),
      value: 0,
      revenue: 0,
      revenueLift: 0,
      impressions: 0,
      fillRate: 0,
      cpm: 0,
      nameMonth: monthsArray[monthIndex],
    });

    dt.setUTCMonth(dt.getUTCMonth() + 1);
    dt.setUTCDate(1); // Reset to the first day of the month
  }

  return arr;
}

function generateTemplateLineChartWidgetGroupedQuarterly(start, endDate) {
  let arr = [];
  let dt = new Date(start);
  let end = new Date(endDate);
  let quartersArray = ["Q1", "Q2", "Q3", "Q4"];

  while (dt <= end) {
    let quarterIndex = Math.floor(dt.getUTCMonth() / 3);
    arr.push({
      date: dt.toISOString().split("T")[0],
      dateAsStr: prepareDateFormatUTC(dt),
      value: 0,
      revenue: 0,
      revenueLift: 0,
      impressions: 0,
      fillRate: 0,
      cpm: 0,
      nameQuarter: quartersArray[quarterIndex],
    });

    dt.setUTCMonth(dt.getUTCMonth() + 3);
  }

  return arr;
}

function generateTemplateLineChartWidgetGroupedAggregated(start, endDate) {
  let end = new Date(endDate);
  let arr = [];
  arr.push({
    date: end.toISOString().split("T")[0],
    dateAsStr: prepareDateFormatUTC(end),
    value: 0,
    revenue: 0,
    revenueLift: 0,
    impressions: 0,
    fillRate: 0,
    cpm: 0,
  });
  return arr;
}

const prepareObjLineChartWidget = (data, templateChart, pct) => {
  for (let j of templateChart) {
    for (let k = 0; k < data.length; k++) {
      if (j.dateAsStr === data[k].submitted_date) {
        let revenue = setOfCalculationsToInfoBoxLineChart["revenue"](data[k], pct);
        let revenueLift = setOfCalculationsToInfoBoxLineChart["revenueLift"](data[k], pct);
        let impressions = setOfCalculationsToInfoBoxLineChart["impressions"](data[k], pct);
        let fillRate = setOfCalculationsToInfoBoxLineChart["fillRate"](data[k], pct);
        let cpm = setOfCalculationsToInfoBoxLineChart["cpm"](data[k], pct);

        //fill rate lift - values should be agregated first, then calculated (using setOfAdditionalCalculationsToInfoBoxLineChart)
        j.fillRate = sumObjectsByKey(j.fillRate, fillRate);
        j.revenue += revenue;
        j.revenueLift += revenueLift;
        j.impressions += impressions;
        j.cpm += cpm;
      }
    }

    //additional calculations for fill rate lift
    if ("fillRate" in setOfAdditionalCalculationsToInfoBoxLineChart && Object.keys(j.fillRate).length) {
      j.fillRate = setOfAdditionalCalculationsToInfoBoxLineChart["fillRate"](j, pct);
    }
  }

  return templateChart;
};

const prepareLineChartsForWidgetBoxes = (data, startDate, chartLastDate, pct = 95, dateGroupingMode = 1) => {
  let datas = {};
  let widgetChartTemplate;
  switch (dateGroupingMode) {
    case 2: {
      widgetChartTemplate = generateTemplateLineChartWidgetGroupedMonthly(startDate, chartLastDate);
      break;
    }
    case 3: {
      widgetChartTemplate = generateTemplateLineChartWidgetGroupedQuarterly(startDate, chartLastDate);
      break;
    }
    case 4: {
      widgetChartTemplate = generateTemplateLineChartWidgetGroupedAggregated(startDate, chartLastDate);
      break;
    }
    default: {
      widgetChartTemplate = generateTemplateLineChartWidget(startDate, chartLastDate);
    }
  }

  datas = prepareObjLineChartWidget(data, widgetChartTemplate, pct);

  return datas;
};

function getTemplateWithBase(category, chartType) {
  let arr = new Array();
  for (let i of category) {
    arr.push({
      name: i,
      ...setOfTemplateProperties[chartType](),
    });
  }

  return arr;
}

const prepareObj = (data, templateChart, fieldName, chartType) => {
  let map = new Map();
  for (let k = 0; k < data.length; k++) {
    //get Key-Values Pairs
    if ("client_type" in data[k]) map.set(data[k].client_type, data[k].browser);
    if ("site_id" in data[k]) map.set(data[k].site_id, data[k].dpsite_vr_id);
    if ("placement_id" in data[k]) map.set(data[k].placement_id, data[k].placement_id);

    for (let j = 0; j < templateChart.length; j++) {
      if (templateChart[j].dateAsStr === data[k].submitted_date) {
        let itemName = data[k][fieldName];
        let valRes = setOfReturnedReportValues[chartType](data[k]);

        if ([itemName] in templateChart[j]) {
          templateChart[j][itemName] += valRes;
        } else {
          templateChart[j][itemName] = valRes;
        }
      }
    }
  }

  return { templateChart, keyValuePairs: map };
};

const prepareObjAB = (data, templateChart, fieldName, chartType) => {
  if ([chartType] in setOfCalculatedProperties === false) return [];

  for (let j of templateChart) {
    // let notNullValues = makeHash();
    for (let k of data) {
      if (j.name === k[fieldName]) {
        // if (k.avg_cpma !== null && k.avg_cpma !== 0 && chartType === 2) notNullValues("avg_cpma", 1);
        // if (k.avg_cpmb !== null && k.avg_cpmb !== 0 && chartType === 2) notNullValues("avg_cpmb", 1);
        j = setOfCalculatedProperties[chartType](j, k);
        j.name = k[fieldName];
      }
    }

    if ([chartType] in setOfAdditionalCalculations) {
      j = setOfAdditionalCalculations[chartType](j, 0);
      // j = setOfAdditionalCalculations[chartType](j, notNullValues());
    }
  }
  return templateChart;
};

const getPercentageDifference = (prevData, currentData) => {
  if (prevData === 0 && currentData === 0) return 0;
  //TODO: how to deal with zero values?
  // if (prevData == Infinity || currentData == Infinity) return 0;
  //return (((currentData - prevData) / Math.abs(currentData)) * 100).toFixed(2);
  return (((currentData - prevData) / Math.abs(prevData)) * 100).toFixed(2);
};

const getPercentageDifferenceByPrevDate = (prevData, currentData) => {
  return (((currentData - prevData) / Math.abs(prevData)) * 100).toFixed(2);
};

const getValuesFromRawDataForWidgets = (array) => {
  return array.reduce((accumulator, item) => {
    Object.keys(item).forEach((key) => {
      let val = Number(item[key]);
      if (typeof val === "number" && !Number.isNaN(val)) {
        accumulator[key] = (accumulator[key] || 0) + val;
      }
    });
    return accumulator;
  }, {});
};
const renderVector = (num) => (num > 0 ? "+" + num : num);

const convertToValues = (summary, pct = 95) => {
  if (!Object.keys(summary).length) {
    return {
      revenue: null,
      revenueLift: null,
      impressions: null,
      cpm: null,
      fillRate: null,
      revenueLiftPCT: null,
      cpmPCT: null,
    };
  }

  let sum_total_cpm1 = summary.total_cpm1;
  let sum_total_cpm2 = summary.total_cpm2;

  let sum_total_rows1 = summary.total_rows1;
  let sum_total_rows2 = summary.total_rows2;

  let sum_avg_cpma = (1000 * summary.total_cpm1) / summary.total_rows1;
  let resTotalCpm2 = 1000 * summary.total_cpm2;
  let sum_avg_cpmb =
    resTotalCpm2 === 0 || summary.total_rows2 === 0 ? 0 : (1000 * summary.total_cpm2) / summary.total_rows2;

  let normalizedGrB = pct === 100 ? pct * sum_total_rows2 : (pct / (100 - pct)) * sum_total_rows2;

  // calculate revenueLift: Math.round(+(sum_total_cpm1 - sum_total_cpm2 * pct / (100 - pct)))
  let sumTotalCpm2Normilized = pct === 100 ? sum_total_cpm2 * pct : (sum_total_cpm2 * pct) / (100 - pct);
  let revLift = Math.round(+(sum_total_cpm1 - sumTotalCpm2Normilized));

  // calculate revenueLift (percentage)
  let revLiftPct = (revLift / sumTotalCpm2Normilized) * 100;

  // calculate cpm: sum_avg_cpma - sum_avg_cpmb
  let cpmLift = sum_avg_cpma - sum_avg_cpmb;
  // calculate cpmLift (percentage)
  let cpmLiftPct = (cpmLift / sum_avg_cpmb) * 100;

  return {
    revenue: Math.round(+(sum_total_cpm1 + sum_total_cpm2)),
    revenueLift: revLift,
    revenueLiftPCT: renderVector(revLiftPct.toFixed(1)),
    // revenue: Math.round(+(sum_total_cpm1 / 0.95 - sum_total_cpm2 * 20)),
    impressions: +sum_total_rows1 + (+sum_total_rows2 || 0),
    cpm: sum_avg_cpma - sum_avg_cpmb,
    cpmPCT: renderVector(cpmLiftPct.toFixed(1)),
    fillRate: normalizedGrB === 0 ? 0 : 100 * (sum_total_rows1 / normalizedGrB - 1),
  };
};

const filterValues = (items, ids, key = "browser") => {
  let filteredItems = items.filter((i) => {
    return ids.includes(i[key]);
  });

  return filteredItems;
};

const filterStatsDataByUnitIds = (stats, chartBase, unit) => {
  let summaryFilterBase = unit.map((i) => i.unitId);
  switch (chartBase) {
    case "client_type":
      stats = filterValues(stats, summaryFilterBase, "browser");
      break;
    case "site_id":
      stats = filterValues(stats, summaryFilterBase, "dpsite_vr_id");
      break;
    case "biddercode":
      summaryFilterBase = unit.map((i) => i.clientItemName);
      stats = filterValues(stats, summaryFilterBase, "biddercode");
      break;
    case "placement_id":
      summaryFilterBase = unit.map((i) => +i.clientItemName);
      stats = filterValues(stats, summaryFilterBase, "placement_id");
      break;
  }

  return stats;
};

const excludeValueOfAbChartData = (abGroupChartData, levelOfAllowableValues) => {
  //skip very low or minor values - see state clutterRemovalPercentage
  let excludedValues = [];
  let filteredWithLevelOfAllowableValues = abGroupChartData.filter((i) => {
    if (i.gr_a_imp > levelOfAllowableValues) {
      return i;
    }
    excludedValues.push(i);
  });
  return { excludedValues, filteredWithLevelOfAllowableValues };
};

const excludeValueOfLiftChartData = (liftTypeChartData, excludedValues) => {
  //skip very low or minor values - see state clutterRemovalPercentage
  for (let i of liftTypeChartData) {
    for (let objProp in i) {
      for (let excludedItem of excludedValues) {
        if (objProp === excludedItem.name) delete i[objProp];
      }
    }
  }

  return liftTypeChartData;
};

const convertDataRawDataForWidgetsPreviousPeriod = (stats, clutterRemovalPercentage, base) => {
  if (stats.length === 0) return { agregatedValues: [], filteredStats: [] };

  let agregatedValues = getValuesFromRawDataForWidgets(stats);
  let levelOfAllowableValues = (agregatedValues.total_rows1 * clutterRemovalPercentage) / 100;
  let mapOfUniqsPrev = uniqs(stats, [base]);
  let searchParams = Array.from(mapOfUniqsPrev.get(base));
  let imprTempl = getTemplateWithBase(searchParams, 1);

  let resPrev = prepareObjAB(stats, imprTempl, base, 1);

  //filter data, ignoring very low or minor values
  let filteredAbChartType = {
    excludedValues: [],
    filteredWithLevelOfAllowableValues: [],
  };

  let statsFiltered = stats;

  if (!!levelOfAllowableValues) {
    filteredAbChartType = excludeValueOfAbChartData(resPrev, levelOfAllowableValues);
    let ids = filteredAbChartType.excludedValues.map((i) => i.name);
    statsFiltered = statsFiltered.filter((i) => {
      return !ids.includes(i[base]);
    });
    agregatedValues = getValuesFromRawDataForWidgets(statsFiltered);
  }

  return { agregatedValues, filteredStats: stats };
};

const getReportPctModes = (data, ppPct = [], ppCurrency = []) => {
  let pairs = getUniqPairsOfCurrencyAndPct(data);
  let setPct = new Set();
  let setCurrency = new Set();
  let currencyPctTotalRows = {};

  for (let i = 0; i < data.length; i++) {
    const isPctValid = ppPct.length === 0 || ppPct.some((p) => p.name === data[i].pct);
    const isCurrencyValid = ppCurrency.length === 0 || ppCurrency.some((c) => c.name === data[i].currency);
    if (isPctValid && isCurrencyValid) {
      setPct.add(data[i].pct);
      setCurrency.add(data[i].currency);
      const key = `${data[i].currency}-${data[i].pct}`;
      if (!currencyPctTotalRows[key]) {
        currencyPctTotalRows[key] = 0;
      }
      currencyPctTotalRows[key] += parseInt(data[i].total_rows1, 10);
    }
  }

  const maxCurrencyPct = Object.keys(currencyPctTotalRows).reduce((max, key) => {
    return currencyPctTotalRows[key] > currencyPctTotalRows[max] ? key : max;
  }, Object.keys(currencyPctTotalRows)[0]);

  const [maxCurrency, maxPct] = maxCurrencyPct.split("-");

  return {
    pctModes: Array.from(setPct)
      .slice()
      .sort((a, b) => b - a),
    currencyModes: Array.from(setCurrency)
      .slice()
      .sort((a, b) => b.localeCompare(a)),
    uniqPairs: pairs,
    maxCurrencyPctCombination: {
      currency: maxCurrency,
      pct: parseInt(maxPct, 10),
      totalRows: currencyPctTotalRows[maxCurrencyPct],
    },
  };
};

const convertToZeroValues = () => {
  return {
    revenue: 0,
    revenueLift: 0,
    impressions: 0,
    cpm: 0,
    fillRate: 0,
  };
};

const getReportValues = (action, replacement = "client_type") => {
  return {
    stats: action.payload.result.data,
    activeRangeDate: action.payload.activeRangeDate,
    dateGroupingMode: action.payload.dateGroupingMode,
    // pct: action.payload.result.data[0].pct,
    base: action.payload.filterBase === "summary" ? replacement : action.payload.filterBase,
  };
};

const prepareReportDates = (activeRangeDate, action, state, rpp = false) => {
  let date = new Date();
  let reportDateStart = undefined;
  let chartLastDate = undefined;
  if (activeRangeDate === CONSTANT.DATE_RANGE_TYPES.ALL_TIME.value) {
    reportDateStart = { startDate: getAllTimeFirstDate(action.payload.result.data) };
    let yesterday = new Date();
    if (action.payload.dateGroupingMode === CONSTANT.DATE_GROUP_MODES.MONTH)
      yesterday.setMonth(yesterday.getMonth() - 1);
    else yesterday.setDate(yesterday.getDate() - 1);
    chartLastDate = +yesterday;
  } else {
    if (activeRangeDate === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
      if (rpp) {
        reportDateStart = {
          startDate: state.customDateRangePreviousPeriod.customDateStart,
        };
        chartLastDate = +new Date(state.customDateRangePreviousPeriod.customDateEnd);
      } else {
        reportDateStart = {
          startDate: action.payload.customDateRange.customDateStart,
        };
        chartLastDate = +new Date(action.payload.customDateRange.customDateEnd);
      }
    } else {
      if (rpp) {
        //calculate dates for report previous period  - rpp
        if (activeRangeDate === CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value) {
          let { start, end } = getPrevMonthDates(2);
          reportDateStart = { startDayNumber: 1, startDate: start };
          chartLastDate = new Date(end);
        } else {
          reportDateStart = getDateAgo(date, action.payload.activeRangeDate);
          let endDate = getDateAgo(date, action.payload.activeRangeDate / 2).startDate;
          chartLastDate = endDate.setDate(endDate.getDate() - 1);
        }
      } else {
        if (activeRangeDate === CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value) {
          let { start, end } = getPrevMonthDates();
          reportDateStart = { startDayNumber: 1, startDate: start };
          chartLastDate = new Date(end);
        } else {
          let dateCopy = new Date(date);
          reportDateStart = getDateAgo(date, activeRangeDate);
          chartLastDate = dateCopy.setDate(date.getDate() - 1);
        }
      }
    }
  }

  return {
    reportDateStart,
    chartLastDate,
  };
};

const convertRawData = (
  stats,
  category = "client_type",
  reportDateStart,
  chartLastDate,
  active_chart_type,
  arrOfKeys,
  levelOfAllowableValues = null,
  dateGroupingMode = 1
) => {
  let mapOfUniqs = uniqs(stats, arrOfKeys);

  let searchParams = Array.from(mapOfUniqs.get(category));
  let activeChartType = active_chart_type === 0 ? 1 : active_chart_type;

  //prepare data for line chart
  let template;

  switch (dateGroupingMode) {
    case 2: {
      template = getDateArrayWithCategoryGroupedMonthly(reportDateStart, chartLastDate, searchParams);
      break;
    }
    case 3: {
      template = getDateArrayWithCategoryGroupedQuarterly(reportDateStart, chartLastDate, searchParams);
      break;
    }
    case 4: {
      template = getDateArrayWithCategoryAggregated(reportDateStart, chartLastDate, searchParams);
      break;
    }
    default: {
      template = getDateArrayWithCategory(reportDateStart, chartLastDate, searchParams);
    }
  }
  let lift_type = prepareObj(stats, template, category, activeChartType);

  //prepare data for bar chart
  let templateAB = getTemplateWithBase(searchParams, activeChartType);
  let ab_group_type = prepareObjAB(stats, templateAB, category, activeChartType);

  //filter data, ignoring very low or minor values
  let filteredAbChartType = {
    excludedValues: [],
    filteredWithLevelOfAllowableValues: [],
  };
  let filteredWithLevelOfAllowableValuesLiftType = [];
  if (!!levelOfAllowableValues) {
    filteredAbChartType = excludeValueOfAbChartData(ab_group_type, levelOfAllowableValues);
    filteredWithLevelOfAllowableValuesLiftType = excludeValueOfLiftChartData(
      lift_type.templateChart,
      filteredAbChartType.excludedValues
    );
  }

  return {
    lift_type:
      filteredWithLevelOfAllowableValuesLiftType.length > 0
        ? filteredWithLevelOfAllowableValuesLiftType
        : lift_type.templateChart,
    ab_group_type:
      filteredAbChartType.filteredWithLevelOfAllowableValues.length > 0
        ? filteredAbChartType.filteredWithLevelOfAllowableValues
        : ab_group_type,
    keyValuePairs: Array.from(lift_type.keyValuePairs),
    excludedValues: filteredAbChartType.excludedValues,
  };
};

export {
  uniqs,
  prepareObj,
  getDateArrayWithCategory,
  convertRawData,
  getPercentageDifference,
  getValuesFromRawDataForWidgets,
  convertToValues,
  filterValues,
  generateTemplateLineChartWidget,
  prepareObjLineChartWidget,
  getTemplateWithBase,
  prepareObjAB,
  excludeValueOfAbChartData,
  convertDataRawDataForWidgetsPreviousPeriod,
  prepareLineChartsForWidgetBoxes,
  getReportValues,
  prepareReportDates,
  getPercentageDifferenceByPrevDate,
  getReportPctModes,
  convertToZeroValues,
  filterStatsDataByUnitIds,
  getDateArrayWithCategoryGroupedQuarterly,
};
