import React, { useMemo, useState } from "react";
import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { ISession } from "./monitoringApi";
import { getDailyMetrics, getDaysForTimeRange, IDailyMetric } from "./utils";
import SelectItems, { Item } from "../components/SelectItems/SelectItems";
import { cardSx, TimeRange } from "./DashboardCard";
import { graphColors } from "../theme";
import { CustomizedAxisTick } from "../components/chartTemplates/utils";

interface IMonitoringChartWrapperProps {
  sessions: ISession[];
  setSelectedRangeCallback: (newTimeRange: TimeRange) => void;
}

export enum EMetricKey {
  AverageSessionDuration = "averageSessionDuration",
  ActiveUsers = "activeUsers",
  TotalRequests = "totalRequests",
}
const metricConfig = [
  {
    key: EMetricKey.AverageSessionDuration,
    title: "Avg Duration",
    prefix: "min",
    label: "Average Session Duration",
  },
  {
    key: EMetricKey.ActiveUsers,
    title: "Active Users",
    prefix: "",
    label: "Number of Active Users",
  },
  {
    key: EMetricKey.TotalRequests,
    title: "Total Requests",
    prefix: "",
    label: "Total Requests",
  },
];

export interface IMonitoringItem extends Omit<Item, "id" | "name"> {
  id: EMetricKey;
  name: EMetricKey;
}

export interface IRangeItem extends Omit<Item, "id" | "name"> {
  id: TimeRange;
  name: TimeRange;
}

function getYAxisLabel(metric: EMetricKey): string {
  switch (metric) {
    case EMetricKey.AverageSessionDuration:
      return "Avg Duration (min)";
    case EMetricKey.ActiveUsers:
      return "Active Users";
    case EMetricKey.TotalRequests:
      return "Total Requests";
    default:
      return "";
  }
}

export const MonitoringChartWrapper: React.FC<IMonitoringChartWrapperProps> = ({
  sessions,
  setSelectedRangeCallback,
}) => {
  const [selectedRange, setSelectedRange] = React.useState<TimeRange>(TimeRange.P7D);
  const days = getDaysForTimeRange(selectedRange);
  const filteredSessions = useMemo(() => {
    if (!sessions) return [];
    const now = new Date();
    const cutoff = new Date(now.getTime() - days * 24 * 60 * 60 * 1000);
    return sessions.filter((s) => new Date(s.startTime) >= cutoff);
  }, [sessions, days]);
  const dailyMetrics: IDailyMetric[] = getDailyMetrics(filteredSessions || []);
  const [selectedMetric, setSelectedMetric] = useState<EMetricKey>(EMetricKey.AverageSessionDuration);

  const metricItems: IMonitoringItem[] = metricConfig.map((m) => ({
    id: m.key,
    name: m.key,
    title: m.title,
    isSelected: selectedMetric === m.key,
    body: <Typography>{m.label}</Typography>,
    areaChartType: "",
    prefix: m.prefix,
    isSingleSelection: true,
  }));

  const timeRangeItems: IRangeItem[] = Object.values(TimeRange).map((rangeValue) => ({
    id: rangeValue,
    name: rangeValue,
    title: rangeValue,
    isSelected: selectedRange === rangeValue,
    body: <Typography>{rangeValue}</Typography>,
    areaChartType: "",
    prefix: "",
    isSingleSelection: true,
  }));

  const handleChangeRange = (event: SelectChangeEvent<string[]>) => {
    const newValue = [event.target.value];
    if (Array.isArray(newValue) && newValue.length) {
      setSelectedRange(newValue[0] as TimeRange);
      setSelectedRangeCallback(newValue[0] as TimeRange);
    }
  };

  const handleChangeMetric = (event: SelectChangeEvent<string[]>) => {
    const newValue = [event.target.value];
    if (Array.isArray(newValue) && newValue.length) {
      setSelectedMetric(newValue[0] as EMetricKey);
    }
  };

  return (
    <Box sx={{ ...cardSx, width: "100%", height: "100%", gap: "8px" }}>
      <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end", gap: "16px" }}>
        <SelectItems
          items={timeRangeItems}
          selectTitle="Select time range"
          handleChangeItem={handleChangeRange}
          isSingleSelection
        />
        <SelectItems
          selectWidth={"190px"}
          menuWidth={"250px"}
          items={metricItems}
          selectTitle="Select Metric"
          handleChangeItem={handleChangeMetric}
          isSingleSelection
        />
      </Box>

      <Box sx={{ width: "100%", height: "100%" }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={dailyMetrics} margin={{ top: 10, right: 5, left: 2, bottom: 5 }}>
            <CartesianGrid stroke="#f5f5f5" strokeWidth={1} />
            <XAxis dataKey="date" width={2} axisLine={false} tick={<CustomizedAxisTick />} />
            <YAxis
              type="number"
              domain={["auto", "auto"]}
              allowDecimals={false}
              tickLine={{ stroke: "none" }}
              axisLine={false}
              label={{
                value: getYAxisLabel(selectedMetric),
                angle: -90,
                position: "insideLeft",
                style: { textAnchor: "middle" },
                fill: "gray",
                fontSize: 14,
              }}
              tick={<CustomizedAxisTick />}
            />
            <Tooltip />
            {/* <Legend /> */}
            <Line
              type="linear"
              dataKey={selectedMetric}
              stroke={graphColors.blueGraph100}
              activeDot={false}
              strokeWidth={3}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};
