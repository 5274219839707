import { Box, Chip, Typography } from "@mui/material";
import { colors } from "../theme";
import { JSAgentStatsDataType } from "./jsAgentApi";
import { convertLocal } from "../utils";

interface JSAgentStatsCardProps {
  item: JSAgentStatsDataType;
  gapElements?: string;
}

const JSAgentStatsCard: React.FC<JSAgentStatsCardProps> = ({ item, gapElements = "10px" }) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100px",
        flexDirection: "column",
        alignItems: "flex-start",
        border: `1px solid ${colors.borderDefault}`,
        borderRadius: "16px",
        padding: "12px",
        // gap: "10px",
        flex: `0 0 calc(25% - ${gapElements})`,
        // minWidth: "25%",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "4px",
        }}
      >
        <Chip
          sx={{
            borderRadius: "8px",
            background: colors.blue50,
            color: colors.contentInfo,
            fontSize: "11px",
            lineHeight: "16px",
            fontWeight: 500,
            padding: "2px 4px",
          }}
          label={item.partnerName}
          color="warning"
        />
      </Box>
      <Typography variant="xLarge600">{convertLocal(+item.impressions)}</Typography>
      <Typography
        sx={{ marginTop: "auto", alignSelf: "flex-end" }}
        variant="bodyXSmallMedium"
        color={colors.textBaseTertiary}
      >
        daily average to previos week
      </Typography>
    </Box>
  );
};

export default JSAgentStatsCard;
