import { Box, Button, Chip, Typography } from "@mui/material";
import { colors } from "../theme";
import { useNavigate } from "react-router-dom";
import { CONSTANT } from "../constants/constants";
import { JSAgentTypes, JSAgent, handleDownload, AgentTags, agentTagColors } from "./jsAgentApi";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useEffect, useState } from "react";
import { message } from "antd";

interface JSAgentCardProps {
  item: JSAgent;
  sizeType?: "main" | "list";
  gapElements: string;
}

const JSAgentCard: React.FC<JSAgentCardProps> = ({ item, sizeType = "list", gapElements = "10px" }) => {
  const token = useSelector((state: RootState) => state.users.loggedUser.token) ?? "";
  const navigate = useNavigate();
  const [downloadResult, setDownloadResult] = useState<{ type: number; message: string } | null>(null);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (downloadResult) {
      if (downloadResult.type) {
        messageApi.open({
          type: "success",
          content: downloadResult.message,
        });
      } else {
        messageApi.open({
          type: "error",
          content: downloadResult.message,
        });
      }
    }
    setDownloadResult(null);
  }, [downloadResult]);

  return (
    <Box
      sx={{
        display: "flex",
        height: sizeType === "main" ? "170px" : "140px",
        flexDirection: "column",
        alignItems: "flex-start",
        border: `1px solid ${colors.borderDefault}`,
        borderRadius: "24px",
        padding: "16px",
        gap: "10px",
        cursor: "pointer",
        flex: sizeType === "main" ? `0 0 calc(50% - ${gapElements})` : `0 0 calc(33.333% - ${gapElements})`, // Set explicit flex-basis
        maxWidth: sizeType === "main" ? "50%" : "33.333%", // Prevent overflowing
        boxSizing: "border-box", // Ensures padding doesn't affect size
      }}
      onClick={() => navigate(`${CONSTANT.PAGES.JS_AGENT.path}/${item.version}`)}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {item.tag ? (
          <Chip
            sx={{
              borderRadius: "8px",
              background: agentTagColors[item.tag].background,
              color: agentTagColors[item.tag].textColor,
              fontSize: "11px",
              fontWeight: 500,
            }}
            label={item.tag}
          />
        ) : (
          <Typography variant="xLarge600">{item.version}</Typography>
        )}
        <Chip
          sx={{
            borderRadius: "8px",
            background: colors.navy50,
            color: colors.text02,
            fontSize: "11px",
            fontWeight: 500,
          }}
          label={item.usagesCount}
        />
      </Box>
      {item.tag && <Typography variant="xLarge600">{item.version}</Typography>}
      {item.releaseDate && (
        <Typography variant="body2XSmall" color={colors.textBaseTertiary}>
          <strong>Release date:</strong> {moment(item.releaseDate).format("DD-MM-YYYY HH:mm:ss")}
        </Typography>
      )}

      <Button
        variant="text"
        sx={{
          display: "flex",
          marginTop: "auto",
          alignSelf: "flex-end",
          color: colors.brandDefault,
          fontSize: "14px",
          fontWeight: 600,
          textAlign: "center",
          fontStyle: "normal",
          lineHeight: "20px",
        }}
        onClick={(e) => {
          e.stopPropagation();
          handleDownload({ version: item.version, agentType: JSAgentTypes.Universal }, token, setDownloadResult);
        }}
      >
        Download
      </Button>
      {contextHolder}
    </Box>
  );
};

export default JSAgentCard;
