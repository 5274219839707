import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import { cardWrapperStl, avatarStl } from "./styles";
import { formatDate } from "../../utils";
import { fetchAccountImg } from "../api/accounts-api";
import UserStatusChip from "../../components/Chips/UserStatusChip";

let cardItemStl = {
  fontSize: "1rem",
  color: "#506077",
  marginBottom: "1rem",
};

let cardBadgeStl = {
  marginLeft: "1rem",
  background: "#EDFEF5",
  padding: "0.25rem",
  fontSize: "0.825rem",
  borderRadius: "4px",
};

let gridItemStl = {
  padding: "0rem 0rem 0.75rem",
  textAlign: "left",
};

export default function AccountCard(props) {
  const [img, setImg] = useState();
  const imgRef = useRef(null);

  useEffect(() => {
    let observer;
    const fetchImage = async () => {
      const res = await fetchAccountImg(props.d.id, props.signal);
      setImg(res);
    };

    if (imgRef.current) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry?.isIntersecting) {
              fetchImage();
              if (imgRef.current instanceof Element && observer) {
                observer.unobserve(imgRef.current);
              }
            }
          });
        },
        {
          rootMargin: "0px",
          threshold: 0.1,
        }
      );

      observer.observe(imgRef.current);
    }

    return () => {
      if (observer && imgRef.current instanceof Element) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [props.d.id, props.signal]);

  return (
    <Box
      id="account-card"
      ref={imgRef}
      sx={{
        ...cardWrapperStl,
        padding: "1.5rem 1rem 1rem 5rem",
        position: "relative",
        boxShadow: "0px 12px 20px 0px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "flex-start",
      }}
    >
      <Box sx={{ display: "flex", marginBottom: "1rem" }}>
        <Box
          id="account-name-field"
          sx={{ fontSize: "1.5rem", fontWeight: 600, position: "relative", textAlign: "left", display: "flex" }}
        >
          {props.d.company}
          <Box id="account-status" sx={{ marginLeft: "1rem" }}>
            <UserStatusChip status={props.d.status} />
          </Box>
        </Box>
      </Box>

      <Box sx={{ ...cardItemStl, display: "flex", justifyContent: "space-between" }}>
        <Box id="creation-date-field">
          Date of creation: {formatDate(new Date(props.d.creationDate)).fullFormat}
        </Box>
      </Box>

      <Grid container sx={{ ...cardItemStl }}>
        <Grid item sx={gridItemStl} xs={6}>
          Account id:
        </Grid>
        <Grid item sx={gridItemStl} xs={6}>
          Account users:
        </Grid>
        <Grid id="account-id-field" item sx={{ ...gridItemStl, fontWeight: 600 }} xs={6}>
          {props.d.id}
        </Grid>
        <Grid id="account-users-field" item sx={{ ...gridItemStl, fontWeight: 600 }} xs={6}>
          {props.d.users?.length}
        </Grid>
      </Grid>

      <Box sx={{ fontSize: "1rem", fontWeight: 600, position: "absolute", top: "1rem", left: "20px" }}>
        {!!img ? (
          <Fade in={true} timeout={1000}>
            <Box
              sx={{
                width: 35,
                height: 35,
                backgroundImage: `url(${img})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center",
              }}
            >
              {/* <img src={img} /> */}
            </Box>
          </Fade>
        ) : (
          <Avatar
            id="avatar"
            sx={{
              ...avatarStl,
              width: 35,
              fontSize: "0.75rem",
              height: 35,
              backgroundColor: "#bebebe",
            }}
          >
            {props.d.company[0] ?? ""}
          </Avatar>
        )}
      </Box>
    </Box>
  );
}
