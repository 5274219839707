import { Action, ThunkDispatch, configureStore } from "@reduxjs/toolkit";
import reportReducer from "../report/reportSlice";
import roleReducer from "../role/roleSlice";
import chartSelectoReducer from "../chartSelector/chartSelectorSlice";
import summaryReducer from "../summary/summarySlice";
import savedRawDataReducer from "../savedRawData/savedRawDataSlice";
import savedRawDataPreviousPeriodReducer from "../savedRawDataPreviousPeriod/savedRawDataPreviousPeriodSlice";
import advancedReportReducer from "../core/performanceReports/advancedReport/advancedReportSlice";
import usersSlice from "../accounts/users/usersSlice";
import { accountsApi } from "../accounts/accountsApi";
import { connectionsApi } from "../accounts/auth0Connections/connectionsApi";
import { useDispatch } from "react-redux";
import platformParametersSliceReducer from "../parametersConstancy/platformParametersSlice";
import { filterGroupsApi } from "../parametersConstancy/parametersContancyFetches";
import { notificationsApi } from "../core/notifications/notificationsApi";
import { partnersApi } from "../accounts/partnersApi";
import { configurationsApi } from "../core/configuration/configurationsApi";
import { monitoringApi } from "../monitoring/monitoringApi";
import { jsAgentsApi } from "../js-agents/jsAgentApi";

export const store = configureStore({
  reducer: {
    report: reportReducer,
    role: roleReducer,
    chartSelector: chartSelectoReducer,
    summary: summaryReducer,
    savedRawData: savedRawDataReducer,
    savedRawDataPreviousPeriod: savedRawDataPreviousPeriodReducer,
    advancedReport: advancedReportReducer,
    platformParameters: platformParametersSliceReducer,
    users: usersSlice,
    [accountsApi.reducerPath]: accountsApi.reducer,
    [connectionsApi.reducerPath]: connectionsApi.reducer,
    [filterGroupsApi.reducerPath]: filterGroupsApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [partnersApi.reducerPath]: partnersApi.reducer,
    [configurationsApi.reducerPath]: configurationsApi.reducer,
    [monitoringApi.reducerPath]: monitoringApi.reducer,
    [jsAgentsApi.reducerPath]: jsAgentsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(accountsApi.middleware)
      .concat(connectionsApi.middleware)
      .concat(filterGroupsApi.middleware)
      .concat(notificationsApi.middleware)
      .concat(partnersApi.middleware)
      .concat(configurationsApi.middleware)
      .concat(monitoringApi.middleware)
      .concat(jsAgentsApi.middleware),
});
// export type AppDispatch = typeof store.dispatch;
export type AppDispatch = ThunkDispatch<RootState, unknown, Action<string>> & typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
