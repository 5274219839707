import React, { useState, useEffect, useCallback } from "react";
import EventSource from "eventsource";
import auth from "../auth/auth-helper";
import { CONSTANT } from "../constants/constants";
import { getReactAppVersion, getUserMetadataByToken } from "../auth/auth-api";
import { checkVersion } from "./infrastructureHelpers";
import { postLog } from "../components/ErrorBoundary/backendLogApi";
import { store } from "../store/store";
import { accountsApi } from "../accounts/accountsApi";
import { getPartnersListType } from "../role/roleSlice";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { notificationsApi } from "../core/notifications/notificationsApi";
import { useAuth0 } from "@auth0/auth0-react";
import { monitoringApi, useGetSessionsQuery } from "../monitoring/monitoringApi";

interface Props {
  userId: number;
}

const SseEventManager: React.FC<Props> = ({ userId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const [eventSource, setEventSource] = useState<EventSource | null>(null);
  const [reconnectAttempt, setReconnectAttempt] = useState(0);
  const [apiHealthcheckAttempts, setApiHealthcheckAttempts] = useState(0);

  const maxReconnectDelay = 60000; // Maximum delay of 1 minute
  const maxApiHealthcheckAttempts = 3;

  // Connect to SSE
  const connect = useCallback(async () => {
    if (!userId) return;
    if (eventSource) {
      eventSource.close();
    }

    const token = await getAccessTokenSilently();
    const url = `${CONSTANT.path.host}/sse`;

    const newEventSource = new EventSource(url, {
      headers: {
        "Content-Type": "text/event-stream",
        "Cache-Control": "no-cache",
        Connection: "keep-alive",
        Authorization: `Bearer ${token}`,
      },
    });

    newEventSource.onmessage = async (event) => {
      try {
        const parsedData = JSON.parse(event.data);
        if (parsedData.type === CONSTANT.SSE_EVENT_TYPES.HEARTBEAT) {
          return;
        }
        if (parsedData.type === CONSTANT.SSE_EVENT_TYPES.NEW_NOTIFICATION) {
          dispatch(
            notificationsApi.util.updateQueryData("getReceivedNotifications", undefined, (draft) => {
              const index = draft.findIndex((n) => n.id === parsedData.id);
              if (index !== -1) {
                draft[index] = parsedData;
              } else {
                draft.unshift(parsedData);
              }
            })
          );
        }
        if (parsedData.type === CONSTANT.SSE_EVENT_TYPES.ACCOUNT_UPDATED) {
          dispatch(accountsApi.util.invalidateTags(["Account"]));
          dispatch(getPartnersListType({ signal: null }));
        }
        const token = await getAccessTokenSilently();
        if (parsedData.type === CONSTANT.SSE_EVENT_TYPES.REFETCH_USER_METADATA) {
          await getUserMetadataByToken(token, store.dispatch, false, null, true);
        }
        if (parsedData.type === CONSTANT.SSE_EVENT_TYPES.REFETCH_SESSIONS) {
          dispatch(monitoringApi.util.invalidateTags(["Session"]));
        }
        if (parsedData.type === CONSTANT.SSE_EVENT_TYPES.SET_ON_MAINTENANCE) {
          postLog({ level: "error", message: "Redirecting user to maintenance page..." }, token);
          window.location.href = "/maintenance";
        }
      } catch (error) {
        console.error("Error parsing SSE message:", error);
      }
    };

    newEventSource.onopen = () => {
      setReconnectAttempt(0); // Reset reconnect attempt counter on successful connection
      setApiHealthcheckAttempts(0);
    };

    newEventSource.onerror = (error) => {
      if (error instanceof Error) {
        console.error("Error message:", error.message);
        console.error("Error stack:", error.stack);
      }
      if (newEventSource.readyState === EventSource.CLOSED) {
        newEventSource.close();
        scheduleReconnect();
      } else {
        handleError(error);
      }
    };

    setEventSource(newEventSource);
  }, [eventSource]);

  // Handle error
  const handleError = (error: any) => {
    console.error("Handling SSE error:", error);
    getReactAppVersion().then((res) => {
      if (!res?.reactAppVersion || +res?.reactAppVersion === CONSTANT.REACT_APP_VERSION_MAINTENANCE) {
        console.log(`getReactAppVersion attempt ${apiHealthcheckAttempts} failed`);
        setApiHealthcheckAttempts((prev) => prev + 1);
      }
      if (apiHealthcheckAttempts > maxApiHealthcheckAttempts) {
        const message = `maxApiHealthcheckAttempts ${maxApiHealthcheckAttempts} reached, redirecting user to maintenance page`;
        console.log(message);
        postLog({ level: "error", message }, auth.isAuthenticated().data.accessToken);
        setApiHealthcheckAttempts(0);
        checkVersion(res?.reactAppVersion);
      }
    });
  };

  // Schedule reconnection with exponential backoff
  const scheduleReconnect = useCallback(() => {
    const delay = Math.min(1000 * Math.pow(2, reconnectAttempt), maxReconnectDelay);
    console.log(`Attempting to reconnect in ${delay}ms`);

    setTimeout(() => {
      setReconnectAttempt((prev) => prev + 1);
      connect();
    }, delay);
  }, [reconnectAttempt]);

  // Disconnect from SSE
  const disconnect = useCallback(() => {
    if (eventSource) {
      eventSource.close();
      setEventSource(null);
    }
    setReconnectAttempt(0); // Reset reconnect attempt counter on disconnect
  }, [eventSource]);

  useEffect(() => {
    if (userId) {
      connect();
    }

    return () => {
      disconnect();
    };
  }, [userId]);

  return null;
};

export default SseEventManager;
