import moment from "moment";
import { AES, enc, SHA256 } from "crypto-js";
import { CONSTANT } from "./constants/constants";

export const prepareDateFormatUTC = (date) => {
  let d = date.getUTCDate() < 10 ? "0" + date.getUTCDate() : date.getUTCDate();
  let m = date.getUTCMonth() + 1 < 10 ? "0" + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1;
  let y = date.getUTCFullYear();
  return `${y}-${m}-${d}`;
};

export const prepareDateFormat = (date) => {
  return prepareDateFormatUTC(date);
  // let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  // let m = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  // let y = date.getFullYear();
  // return `${y}-${m}-${d}`;
};

export const dateDiffInDays = (a, b) => {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  return Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
};

export const setDays = (date, days) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getDifferenceBettweenDates = (dateStart, dateEnd) => {
  let date1 = new Date(dateStart);
  let date2 = new Date(dateEnd);
  // get time difference of two dates
  let differenceInTime = date2.getTime() - date1.getTime();
  // get amont of days between two dates
  let differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return { differenceInTime, differenceInDays };
};

const getLookup = (limitMetricPrefix) =>
  limitMetricPrefix
    ? [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
      ]
    : [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "B" },
        { value: 1e12, symbol: "TR" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
      ];

export const nFormatter = (
  num,
  digits = 2,
  letters = true,
  commas = false,
  { limitMetricPrefix, setCommas } = {}
) => {
  let isUnsigned = num < 0;
  let signStr = isUnsigned ? "-" : "";
  if (isUnsigned) {
    num = Math.abs(num);
  }

  const formatNumber = (number) => {
    const num = parseFloat(number);
    return commas ? new Intl.NumberFormat().format(num) : num.toString();
  };

  if (commas) {
    if (num % 1 !== 0) return num.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return num.toLocaleString("en-US", { maximumFractionDigits: 0 });
  }

  // const lookup = [
  //   { value: 1, symbol: "" },
  //   { value: 1e3, symbol: "K" },
  //   { value: 1e6, symbol: "M" },
  //   { value: 1e9, symbol: "B" }, // metric prefix G
  //   { value: 1e12, symbol: "TR" }, // T
  //   { value: 1e15, symbol: "P" },
  //   { value: 1e18, symbol: "E" },
  // ];

  let lookup = getLookup(!!limitMetricPrefix);

  let item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value);

  if (item) {
    let pow = Math.pow(10, digits);
    let formattedNumber = Math.round((num / item.value) * pow) / pow;

    if (!!setCommas) {
      return signStr + formattedNumber.toLocaleString("en-US") + (letters ? item.symbol : "");
    }

    return signStr + formatNumber(formattedNumber) + (letters ? item.symbol : "");
  } else {
    return signStr + formatNumber(num.toFixed(digits));
  }
};

export const convertLocal = (num, digits = 2, letters = true, commas = false) => {
  // Check if the number should be abbreviated
  if (Math.abs(num) >= 1e3) {
    return nFormatter(num, digits, letters, commas);
  } else {
    // For numbers less than 1,000, use Intl.NumberFormat for formatting
    let numberFormat = new Intl.NumberFormat("en-US");
    return numberFormat.format(num);
  }
};
export const tablesConvertLocal = (num, digits = 1, letters = false, commas = true) => {
  if (Math.abs(num) < 1e6) {
    // if (Math.abs(num) >= 1e3 && Math.abs(num) < 1e6) {
    let formattedNumber = Math.round((num / 1000) * 10) / 10;
    let numberFormat = new Intl.NumberFormat("en-US");
    return numberFormat.format(formattedNumber);
  } else if (Math.abs(num) >= 1e6) {
    let formattedNumber = Math.round(num / 1000);
    let numberFormat = new Intl.NumberFormat("en-US");
    return numberFormat.format(formattedNumber);
  }
  //  else {
  //   return num;
  // }
};

export const getMonthName = (date, { fullMonthName } = false) => {
  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  let monthsFullName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (!!fullMonthName) return monthsFullName[date.getUTCMonth()];
  return months[date.getUTCMonth()];
};

export const getDateAgo = (date, days) => {
  let dateCopy = new Date(date);
  dateCopy.setDate(date.getDate() - days);
  return { startDayNumber: dateCopy.getDate(), startDate: dateCopy };
};

export const calcCustomDateforPreviousReport = (dateStart, dateEnd, dateGroupMode) => {
  let customDateStart = "";
  let customDateEnd = "";

  const start = new Date(dateStart);
  const end = new Date(dateEnd);

  if (dateGroupMode === CONSTANT.DATE_GROUP_MODES.MONTH || dateGroupMode === CONSTANT.DATE_GROUP_MODES.QUARTER) {
    const monthDiff = end.getUTCMonth() - start.getUTCMonth() + 1; // Period length in months
    // Previous Start Date
    const prevStart = new Date(start);
    prevStart.setUTCMonth(start.getUTCMonth() - monthDiff);
    prevStart.setUTCDate(1); // Set to first day of the month
    customDateStart = prepareDateFormatUTC(prevStart);
  } else {
    // Adjust start date by the difference in days - 1 day to get the day before the previous period starts
    let prevStartDate = new Date(dateStart); // Directly use dateStart, no need to adjust for diffDays here
    let diffDays = dateDiffInDays(start, end);
    prevStartDate.setUTCDate(start.getUTCDate() - diffDays - 1);
    customDateStart = prepareDateFormatUTC(prevStartDate);
  }
  const endDate = new Date(start.setUTCDate(start.getUTCDate() - 1));
  customDateEnd = prepareDateFormatUTC(endDate);

  return { customDateStart, customDateEnd };
};

export const formatDateToStr = (date, { printYear = true, printYYFormat = false } = {}) => {
  const d = new Date(date); // Ensure `date` is parsed as a Date object
  let day = d.getUTCDate(); // Use UTC method
  let y = d.getUTCFullYear(); // Use UTC method
  let month = d.getUTCMonth() + 1; // Use UTC method (months are 0-indexed)

  if (printYYFormat) {
    let yy = y.toString().slice(-2); // Extract last two digits of the year
    let mm = month < 10 ? "0" + month : month; // Ensure 2-digit month
    return `${day}.${mm}.${yy}`;
  }

  if (!printYear) {
    return `${day}.${month}`; // No year included
  }

  return `${day}.${month}.${y}`; // Full date with year
};

export const formatDate = (date) => {
  let d = date.getUTCDate() < 10 ? "0" + date.getUTCDate() : date.getUTCDate();
  let m = date.getUTCMonth() + 1 < 10 ? "0" + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1;
  let months = getMonthName(date);
  let y = date.getUTCFullYear();

  return {
    fullFormat: `${months} ${d}, ${y}`,
    shortFormat: `${d}.${m}.${y.toString().substr(-2)}`,
  };
};

export const sumObjectsByKey = (...objs) => {
  return objs.reduce((a, b) => {
    for (let k in b) {
      if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k];
    }
    return a;
  }, {});
};

export const getPrevMonthDates = (substract = 1) => {
  let start = new Date();
  let end = new Date();

  start.setDate(1);
  start.setMonth(start.getMonth() - substract);

  end.setDate(1);
  if (substract !== 1) end.setMonth(end.getMonth() - 1);
  end.setHours(-1);

  return {
    start: start.toISOString().split("T")[0],
    end: end.toISOString().split("T")[0],
  };
};

export const getPreviousMonthName = ({ monthsOffset = 1, fullMonthName = false } = {}) => {
  let start = new Date();
  start.setDate(1);
  let res = start.setMonth(start.getMonth() - monthsOffset);
  return getMonthName(new Date(res), { fullMonthName });
};

export const isDateEqual = (date1, date2) => {
  let d1 = new Date(date1);
  let d2 = new Date(date2);
  return d1.getTime() === d2.getTime();
};

export const concatUnitAndString = (unit, str) => {
  return unit === "%" ? str + unit : unit + str;
};

export const getUniqPairsOfCurrencyAndPct = (arr) => {
  let set = new Set();
  for (let i = 0; i < arr.length; i++) {
    set.add(`${arr[i].currency}-${arr[i].pct}`);
  }

  let uniqData = Array.from(set).map((item) => {
    let data = item.split("-");
    return { currency: data[0], pct: +data[1] };
  });

  let res = uniqData.slice().sort((a, b) => b.currency.localeCompare(a.currency) || b.pct - a.pct);
  return res;
};

export const makeHash = () => {
  let data = {};

  return (k, v) => {
    if ([k] in data) {
      data[k] += v;
    } else {
      data[k] = v;
    }
    return data;
  };
};

export const capitalizeWords = (sentence) => {
  if (!sentence) {
    console.log(sentence);

    return "Unknown";
  }
  let words = sentence.trim().split(" ");
  if (words.length === 1) return sentence;

  let res = words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");

  return res;
};

export const substractHours = (hours) => {
  const d = new Date();
  d.setHours(d.getHours() - hours + 1);
  d.setMinutes(0, 0, 0);

  return moment(d).valueOf();
};

export const subFormat = (v, chr = `0`) => (v < 10 ? `${chr}${v}` : v);

export const formatDateWithHours = (date) => {
  //format date to yyyy-MM-dd HH:mm:ss
  const d = new Date(date);
  const f =
    [d.getFullYear(), subFormat(d.getMonth() + 1), d.getDate()].join("-") +
    " " +
    [d.getHours(), subFormat(d.getMinutes()), d.getSeconds()].join(":");

  return f;
};

export const currencySymbols = (str) => {
  let s = str.toLowerCase();
  let currency = {
    usd: {
      htmlEntity: "&#36;",
      UTF16Encoding: 0x0024,
    },
    eur: {
      htmlEntity: "&#8364;",
      UTF16Encoding: 0x20ac,
    },
    gbp: {
      htmlEntity: "&#163;",
      UTF16Encoding: 0x00a3,
    },
    yen: {
      htmlEntity: "&#165;",
      UTF16Encoding: 0x00a5,
    },
    inr: {
      htmlEntity: "&#8377;",
      UTF16Encoding: 0x20b9,
    },
  };

  return s in currency ? String.fromCharCode(currency[s].UTF16Encoding) : str;
};

export const validateEmail = (str) => {
  let re1 =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let re2 = /[\u00C0-\u00ff]+/g;
  let res1 = re1.test(String(str).toLowerCase());
  let res2 = re2.test(String(str).toLowerCase());
  return res1 == true && res2 == false;
};

export const validationEmailAddress = (val) => {
  const isValidEmail = /^[\w-\.]+(\+\w+)?@([\w-]+\.)+[\w-]{2,7}$/g;
  return isValidEmail.test(val);
};

export const allowNumbersEnterOnly = (val) => {
  return val.replace(/[^0-9]/g, "");
};

export const compareArr = (a, b) => {
  return a.length === b.length && a.every((v, i) => b[i] === v);
};

export const encryptLS = (key, value) => {
  try {
    const salt = SHA256(process.env.REACT_APP_SALT_PERSISTENCY_SETTINGS).toString();
    const cryptoObj = AES.encrypt(JSON.stringify(value), salt);
    localStorage.setItem(key, cryptoObj.toString());
  } catch (error) {
    localStorage.removeItem(key);
    console.log(error);
    return null;
  }
};

export const decryptLS = (key) => {
  try {
    const salt = SHA256(process.env.REACT_APP_SALT_PERSISTENCY_SETTINGS).toString();
    if (localStorage.getItem(key) === null) {
      return null;
    }
    const encryptedData = AES.decrypt(localStorage.getItem(key), salt);
    let value = JSON.parse(encryptedData.toString(enc.Utf8));
    return value;
  } catch (error) {
    localStorage.removeItem(key);
    console.log(error);
  }
};

export const getMTD = () => {
  const date = new Date();
  return date.getDate() - 1;
};
export const daysAgoSinceStartDate = () => {
  const date = new Date();
  const dt = new Date(date.setDate(date.getDate() - 1));
  let current = new Date(dt.getTime());
  let previous = new Date(dt.getFullYear(), 0, 1);
  return Math.ceil((current - previous + 1) / 86400000);
};

export const saveFile = (blob, fileName) => {
  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);

  // Create an anchor element and simulate a click
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();

  // Clean up by removing the anchor element and revoking the Blob URL
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};
