import React, { useState } from "react";
import { Select, MenuItem, Box, SelectChangeEvent } from "@mui/material";
import CheckboxWrapper from "../CustomCheckbox/Checkbox";
import { colors } from "../../theme";
import ExpandLessIcon from "../IconComponents/ExpandLessIcon";
import ExpandMoreIcon from "../IconComponents/ExpandMoreIcon";

export interface Item {
  id: string;
  name: string;
  title: string;
  isSelected: boolean;
  body: React.ReactNode;
  areaChartType: string;
  prefix: string;
  isSingleSelection: boolean;
}

interface SelectItemsProps {
  items: Item[];
  selectTitle: string;
  handleChangeItem: (event: SelectChangeEvent<string[]>) => void;
  selectWidth?: string;
  menuWidth?: string;
  disableItemCondition?: boolean;
  isSingleSelection?: boolean;
  disabled?: boolean;
  id?: string;
}

const SelectItems: React.FC<SelectItemsProps> = ({
  items,
  selectTitle = "",
  handleChangeItem,
  selectWidth = "130px",
  menuWidth = "215px",
  disableItemCondition = false,
  isSingleSelection = false,
  disabled = false,
  id,
}) => {
  const [open, setOpen] = useState(false);
  const setSelectedLabel = (values: string[]): string => {
    const selectedLabel = items
      .filter((item) => item.isSelected)
      .map((item) => {
        if (item.prefix && item.prefix.trim() !== "") {
          return `${item.title} ${item.prefix}`;
        }
        return item.title;
      })
      .join(", ");
    const res = selectedLabel ? selectedLabel : selectTitle;
    return res;
  };

  const handleValue = () => {
    const itemsIds = items.filter((item) => item.isSelected).map((item) => item.id);
    return itemsIds.length > 0 ? itemsIds : [selectTitle];
  };
  const isNotSelected = items.filter((item) => item.isSelected).length < 1;
  const IconComponent = ({ toggleOpen }: any) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={(e) => {
        e.stopPropagation(); // Prevent the click from bubbling up and triggering other handlers
        toggleOpen();
      }}
    >
      {open ? <ExpandLessIcon /> : <ExpandMoreIcon color={colors.text01} />}
    </Box>
  );
  return (
    <Select
      labelId={id}
      id={id}
      value={handleValue()}
      renderValue={(values) => setSelectedLabel(values)}
      label=""
      onChange={(event, child) => {
        handleChangeItem(event);
        // Prevent closing on item selection for multiple select
        if (!isSingleSelection) {
          event.preventDefault();
        }
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={(event: any) => {
        // Close only if clicking outside the menu or if it's a single selection
        if (event.target.tagName !== "LI" || isSingleSelection) {
          setOpen(false);
        }
      }}
      multiple={!isSingleSelection}
      autoWidth={!selectWidth}
      disabled={disabled}
      IconComponent={() => <IconComponent toggleOpen={() => setOpen(!open)} />}
      sx={{
        border: isNotSelected ? "none" : `1px solid ${colors.borderNeturalDefault}`,
        borderRadius: "24px",
        background: colors.navy50,
        display: "flex",
        alignSelf: "center",
        width: selectWidth,
        padding: isNotSelected ? "4px 12px 4px 16px" : "3px 11px 3px 15px",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        height: "32px",
        gap: "8px",
        "&.Mui-focused": {
          background: colors.navy100,
        },
        "&:focus-within": {
          background: colors.navy100,
        },
        "& .MuiSelect-select": {
          paddingRight: "0 !important",
          border: "none",
        },
        "& .MuiOutlinedInput-input": {
          padding: 0,
          border: "none",
        },
        "&.MuiInputBase-input": {
          padding: 0,
          border: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        slotProps: {
          paper: {
            sx: {
              width: menuWidth,
              marginTop: "8px",
              border: `1px solid ${colors.borderDefault}`,
              borderRadius: "16px",
              boxShadow: "0 40px 40px 0px rgba(16, 29, 65, 0.10)",
              background: `${colors.mainWhite}`,
              padding: "8px",
            },
          },
        },
        MenuListProps: {
          sx: {
            padding: 0,
          },
        },
      }}
    >
      {items.map((graphItem) => (
        <MenuItem
          key={graphItem.id}
          value={graphItem.id}
          sx={{
            padding: 0,
          }}
          disabled={!graphItem.isSelected && disableItemCondition}
        >
          <Box
            id="dropdown-item"
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              padding: "8px",
              gap: "12px",
              borderRadius: "10px",
              margin: "0",
              textAlign: "left",
            }}
          >
            {!isSingleSelection && <CheckboxWrapper id="dropdown-item-checkbox" checked={graphItem.isSelected} />}
            {graphItem.body}
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectItems;
