import React, { useState } from "react";
import {
  CategoriesType,
  CategoryType,
  FilterGroup,
} from "../../../../parametersConstancy/parametersConstancyTypes";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Snackbar, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  useCreateFilterGroupMutation,
  useUpdateFilterGroupMutation,
  useDeleteFilterGroupMutation,
} from "../../../../parametersConstancy/parametersContancyFetches";
import { RootState } from "../../../../store/store";
import { colors } from "../../../../theme";
import CloseIcon from "@mui/icons-material/Close";
import FilterGroupDialogStepper from "./FilterGroupDialogStepper";
import { CONSTANT } from "../../../../constants/constants";
import { performanceReportCategoriesFilters, visitortRecognitionCategoriesFilters } from "../advancedReportSlice";
import NotificationSuccessIcon from "../../../../components/IconComponents/NotificationSuccessIcon";
import { setCurrentMultiselectGroup } from "../../../../role/roleSlice";
import ErrorIcon from "@mui/icons-material/Error";

export const DialogTypes = {
  CREATE: "CREATE",
  EDIT: "EDIT",
};

export enum NotificationStatus {
  SUCCESS = "SUCCESS",
  FAILD = "FAILD",
  IDLE = "IDLE",
}

interface FilterGroupDialogFormProps {
  title: string;
  open?: boolean;
  closeDialog: () => void;
  dialogActionsType: string;
  filterGroup: FilterGroup;
  enableFilters?: boolean;
  type: string;
  id?: string;
}

const FilterGroupDialogForm: React.FC<FilterGroupDialogFormProps> = ({
  title,
  open = false,
  closeDialog,
  dialogActionsType = "",
  filterGroup = null,
  enableFilters = true,
  type = CONSTANT.FILTER_GROUP_TYPE.FILTER,
  id,
}) => {
  const location = useLocation();
  const [createFilterGroup] = useCreateFilterGroupMutation();
  const [updateFilterGroup] = useUpdateFilterGroupMutation();
  const [deleteFilterGroup] = useDeleteFilterGroupMutation();
  const basedFilters: CategoriesType = useSelector((state: RootState) => state.advancedReport.filters);
  let filtersValues: CategoriesType = {};
  let filtersOptions: CategoriesType = {};
  let partnersValues: CategoriesType = {};
  let partnersOptions: CategoriesType = {};
  const { partnersList, partnersSelected }: { partnersList: any[]; partnersSelected: any } = useSelector(
    (state: RootState) => state.role
  );
  const dispatch = useDispatch();

  const isOnlyOnePartner = partnersList.length === 1;
  const dialogTitle =
    title ?? dialogActionsType === DialogTypes.CREATE ? `New ${type} group` : `Edit ${type} group`;
  const [opentNotification, setOpentNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const [notificationActionStatus, setNotificationActionStatus] = useState<NotificationStatus>(
    NotificationStatus.IDLE
  );

  const handleOpentNotification = () => {
    setOpentNotification(true);
  };
  const handleCloseNotification = () => {
    setOpentNotification(false);
    setNotificationActionStatus(NotificationStatus.IDLE);
  };

  let typedActiveFilters: CategoriesType = {};
  if (location.pathname === CONSTANT.PAGES.PERFORMANCE_REPORT.path) {
    Object.entries(basedFilters).forEach(([key, values]) => {
      if (performanceReportCategoriesFilters.includes(key)) {
        typedActiveFilters[key] = values;
      }
    });
  }
  if (location.pathname === CONSTANT.PAGES.VISITOR_RECOGNITION.path) {
    Object.entries(basedFilters).forEach(([key, values]) => {
      if (visitortRecognitionCategoriesFilters.includes(key)) {
        typedActiveFilters[key] = values;
      }
    });
  }
  if (dialogActionsType === DialogTypes.CREATE) {
    if (isOnlyOnePartner) {
      partnersValues = {
        partners: [],
      };
    } else {
      partnersValues = {
        partners: partnersSelected.map((partner: any) => {
          return {
            id: partner.dummy_id,
            name: partner.partner_name,
            isSelected: true,
          };
        }),
      };
      partnersOptions = {
        partners: partnersList
          .filter((partner: any) => !partnersSelected.includes(partner.dummy_id))
          .map((partner) => {
            return {
              id: partner.dummy_id,
              name: partner.partner_name,
              isSelected: false,
            };
          }),
      };
    }
    for (const [key, values] of Object.entries(typedActiveFilters)) {
      filtersValues[key] = [];
      filtersOptions[key] = [];
      for (const unit of values) {
        if (unit.isSelected) {
          filtersValues[key].push(unit);
        } else {
          filtersOptions[key].push(unit);
        }
      }
      if (filtersValues[key].length === 0) {
        delete filtersValues[key];
        delete filtersOptions[key];
      }
    }
  } else if (dialogActionsType === DialogTypes.EDIT && filterGroup) {
    const groupFilters = filterGroup.filters;
    const groupPartners = filterGroup.partners;
    const groupPartnersIds = groupPartners.map((partner) => partner.id);
    partnersValues = { partners: groupPartners };
    partnersOptions = {
      partners: partnersList
        .filter((partner: any) => !groupPartnersIds.includes(partner.dummy_id))
        .map((partner) => {
          return {
            id: partner.dummy_id,
            name: partner.partner_name,
            isSelected: false,
          };
        }),
    };
    Object.keys(typedActiveFilters).forEach((key) => (filtersValues[key] = []));
    filtersOptions = { ...typedActiveFilters };
    Object.entries(groupFilters).forEach(([key, values]) => {
      if (typedActiveFilters[key]) {
        let options: CategoryType[] = [];
        if (values.ids[0] === CONSTANT.REPORTS.SELECTED_ALL_OBJECT.id) {
          filtersValues[key] = typedActiveFilters[key].map((item) => {
            return { ...item, isSelected: true };
          });
        } else {
          options = [...typedActiveFilters[key]];
          values.ids.forEach((id, index) => {
            const optionIndex = options.findIndex((item) => item.id === id);
            if (optionIndex !== -1) {
              options.splice(optionIndex, 1);
            }
            filtersValues[key].push({
              id,
              name: values.names[index],
              isSelected: true,
            });
          });
        }
        filtersOptions[key] = [...options];
      }
    });
  }

  const handleClickCreateGroup = async (filterGroup: FilterGroup) => {
    setNotificationMessage(`New ${type} group added`);
    try {
      delete filterGroup.id;
      const createdGroup = await createFilterGroup(filterGroup).unwrap();
      setNotificationActionStatus(NotificationStatus.SUCCESS);
      const newGroupId = createdGroup.id;
      if (type === CONSTANT.FILTER_GROUP_TYPE.PARTNER)
        dispatch(setCurrentMultiselectGroup({ ...filterGroup, id: newGroupId }));
    } catch (error) {
      setNotificationActionStatus(NotificationStatus.FAILD);
      setNotificationMessage(`Failed to create ${type} group`);
      console.error(`Failed to create ${type} group:`, error);
    } finally {
      handleOpentNotification();
      closeDialog();
    }
  };
  const handleClickUpdateGroup = async (filterGroup: FilterGroup) => {
    setNotificationMessage(`Group ${filterGroup.name} has been changed`);
    try {
      await updateFilterGroup(filterGroup).unwrap();
      setNotificationActionStatus(NotificationStatus.SUCCESS);

      if (type === CONSTANT.FILTER_GROUP_TYPE.PARTNER) dispatch(setCurrentMultiselectGroup(filterGroup));
    } catch (error) {
      setNotificationActionStatus(NotificationStatus.FAILD);
      setNotificationMessage(`Failed to update ${type} group ${filterGroup.name}`);
      console.error(`Failed to update ${type} group ${filterGroup.name}`, error);
    } finally {
      handleOpentNotification();
      closeDialog();
    }
  };
  const handleClickDeleteGroup = async (filterGroup: FilterGroup) => {
    try {
      if (filterGroup) {
        setNotificationMessage(`Group ${filterGroup.name} deleted`);
        await deleteFilterGroup(Number(filterGroup.id)).unwrap();
        setNotificationActionStatus(NotificationStatus.SUCCESS);
        if (type === CONSTANT.FILTER_GROUP_TYPE.PARTNER) dispatch(setCurrentMultiselectGroup(null));
      }
    } catch (error) {
      setNotificationActionStatus(NotificationStatus.FAILD);
      setNotificationMessage(`Failed to delete ${type} group ${filterGroup.name}`);
      console.error(`Failed to delete ${type} group:`, error);
    } finally {
      handleOpentNotification();
      closeDialog();
    }
  };

  return (
    <Box>
      <Dialog
        id={id}
        aria-labelledby={id}
        open={open}
        aria-modal={true}
        onClose={closeDialog}
        sx={{
          background: `${colors.text01}99`,
        }}
        PaperProps={{
          sx: {
            display: "flex",
            width: "600px",
            height: `624px`,
            borderRadius: "24px",
            gap: "16px",
          },
        }}
      >
        <DialogTitle
          id={`${id}-title`}
          sx={{
            textAlign: "start",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "24px 24px 0 24px",
          }}
        >
          <Typography variant="titleSmall">{dialogTitle}</Typography>
        </DialogTitle>
        <IconButton
          id={`${id}-close-btn`}
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: "absolute",
            right: 24,
            top: 24,
            color: `${colors.foreground}`,
            padding: "6px",
          }}
        >
          <CloseIcon
            aria-hidden="false"
            sx={{
              width: "24px",
              height: "24px",
            }}
          />
        </IconButton>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0",
            background: `${colors.foreground01}`,
            borderRadius: "0",
          }}
        >
          <FilterGroupDialogStepper
            dialogActionsType={dialogActionsType}
            filterGroup={filterGroup}
            options={filtersOptions}
            values={filtersValues}
            isOnlyOnePartner={isOnlyOnePartner}
            partnersOptions={partnersOptions}
            partnersValues={partnersValues}
            handleClickDeleteGroup={handleClickDeleteGroup}
            handleClickCreateGroup={handleClickCreateGroup}
            handleClickUpdateGroup={handleClickUpdateGroup}
            enableFilters={enableFilters}
            type={type}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        id="partner-group-upd-toast"
        open={opentNotification && notificationActionStatus !== NotificationStatus.IDLE}
        autoHideDuration={2000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          borderRadius: "24px",
          background: `${colors.backgroundInverse}`,
          padding: "12px",
          boxShadow: "0px 9px 18px rgba(0, 23, 56, 0.05)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          {notificationActionStatus === NotificationStatus.SUCCESS && (
            <NotificationSuccessIcon color={colors.statusSuccessDefault} />
          )}
          {notificationActionStatus === NotificationStatus.FAILD && <ErrorIcon color="error" />}
          <Typography variant="bodyMedium" sx={{ color: `${colors.text05}` }}>
            {notificationMessage}
          </Typography>
        </Box>
      </Snackbar>
    </Box>
  );
};

export default FilterGroupDialogForm;
