import auth from "../../../auth/auth-helper";
import { CONSTANT } from "../../../constants/constants";
import {
  RequestFiltersType,
  VisitorRecoginitonCategoriesRequestDto,
  VisitorRecoginitonDataRequestDto,
  VisitorRecognitionResponseDto,
} from "../types";
import { ThunkDispatch, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState, store } from "../../../store/store";
import { createVRRequestDto } from "./vrUtils";
import { resetSavedCustomRawDataState } from "../../../savedRawData/savedRawDataSlice";
import { checkVersion } from "../../../infrastructure/infrastructureHelpers";
import {
  setFiltersIsLoading,
  setFilterVR,
  visitortRecognitionCategoriesFilters,
} from "../advancedReport/advancedReportSlice";

const SERVER_URL = CONSTANT.path.host;

export const getVisitorRecognitionData = createAsyncThunk<
  VisitorRecognitionResponseDto,
  { requestData: RequestFiltersType; signal: AbortSignal },
  { state: RootState }
>("visitorRecognition/getVisitorRecognitionData", async (payload, thunkApi) => {
  const state = thunkApi.getState();
  const dispatch = thunkApi.dispatch;
  const options: any = state.advancedReport.filters;
  const { requestData, signal } = payload;
  const token = state.users.loggedUser.token;

  let dgm =
    requestData.dgm ??
    state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateGroupingMode ??
    CONSTANT.DATE_GROUP_MODES.DAY;
  Object.entries(requestData).forEach(([base, values]) => {
    if (visitortRecognitionCategoriesFilters.includes(base)) {
      if (
        (base === CONSTANT.REPORTS.FILTERS_KEYS.SITES.base ||
          base === CONSTANT.REPORTS.FILTERS_KEYS.COUNTRIES.base) &&
        ((typeof values[0] !== "number" && values.length > 0 && values.length === options[base].length) ||
          values[0]?.id === "*")
      ) {
        (requestData as any)[base] = [CONSTANT.REPORTS.SELECTED_ALL_OBJECT];
      }
    }
  });

  const requestDto: VisitorRecoginitonDataRequestDto = createVRRequestDto(requestData, dgm);
  const target = "vr";

  try {
    const response = await fetch(`${SERVER_URL}/statistics/${target}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: JSON.stringify({ requestDto }),
      signal,
    });
    const result = await response.json();
    checkVersion(result.reactAppVersion);

    if (!response.ok) {
      throw new Error(
        JSON.stringify({
          message: "server can not get report",
          options: {
            status: response.status,
            message: result.message,
          },
        })
      );
    }
    if (requestData.timeGrouping === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
      dispatch(resetSavedCustomRawDataState({}));
    }
    return result;
  } catch (error: any) {
    console.error("Error fetching the visitor recognition data:", error);
    let errorData;
    try {
      errorData = JSON.parse(error.message);
    } catch {
      errorData = error;
    }
    return thunkApi.rejectWithValue(errorData);
  }
});

export const getVRCategories = async (
  dispatch: ThunkDispatch<any, any, any>,
  partnersIds: number[],
  categories: string[],
  lastFiltersLoadedType: string,
  signal: AbortSignal,
  token: string
) => {
  if (lastFiltersLoadedType === CONSTANT.REPORTS.LAST_FILTERS_LOADED_TYPES.VISITOR_RECOGNITION) {
    return;
  }
  let target = "vr_categories";
  const filteredCategories = categories.filter(
    (category) => category !== CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base
  );

  try {
    let requests = filteredCategories.map((name) => {
      const requestVRCategories: VisitorRecoginitonCategoriesRequestDto = {
        partnersIds,
        categories: [name],
      };
      dispatch(setFiltersIsLoading({ name, value: true }));
      return fetch(`${SERVER_URL}/statistics/${target}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
        body: JSON.stringify({ requestVRCategories }),
        signal,
      })
        .then((response) => response.json())
        .then((data) => dispatch(setFilterVR(data)))
        .catch((error) => console.error("Error fetching categories:", error));
    });
    await Promise.allSettled(requests);
  } catch (error) {
    console.error("Error fetching categories:", error);
  }
};
