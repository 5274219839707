import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { btnWrapper, singleBtn, activeBtn } from "../core/overview/styles";
import AccountDetails from "./tableComponents/AccountDetails";
import AccountCard from "./tableComponents/AccountCard";
import PartnerCard from "./tableComponents/PartnerCard";
import AccountUsersPage from "./AccountUsersPage";
import SideBoardWrapper from "./sideBoard/SideBoardWrapper";
import AdminElement from "../components/ElementWrappers/AdminElement";
import LoaderWithProgress from "../components/LoaderWithProgress/LoaderWithProgress";
import ForbiddenResourceNotification from "../components/notifications/ForbiddenResourceNotification";
import { updImgTitle } from "../role/roleSlice";
import { useGetAccountsQuery } from "./accountsApi";
import { colors } from "../theme";
import { ButtonBase, TextField } from "@mui/material";
import { setParentAccount } from "./users/usersSlice";
import { CONSTANT } from "../constants/constants";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { AccountsTable } from "./AccountsTable";
import { TableCardsToggleSwitch } from "../components/Switch/TableCardsToggleSwitch";
import { PartnersTable } from "./PartnersTable";
import { setAccountsTableView } from "../parametersConstancy/platformParametersSlice";

const selectAccountById = (accounts, id) => {
  return accounts.find((i) => {
    return i.id === id;
  });
};

export default function AccountPage() {
  const [searchInput, setSearchInput] = useState("");
  const { id } = useParams();
  const { data: accounts, isLoading, isFetching, refetch } = useGetAccountsQuery();
  const origin = useSelector((state) => state.users.navigation.origin);
  const tableView = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.accountsParameters.accountsTableView
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  const [subpage, setSubpage] = useState(location.pathname.includes("users") ? 0 : 1);
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [actData, setActData] = useState({});
  const [partnerData, setPartnerData] = useState([]);
  const [virtualAccount, setVirtualAccount] = useState(0);
  const [values, setValues] = useState({
    message: "",
    error: "",
  });
  const { loggedUser } = useSelector((state) => state.users);

  const isActiveButton = (p) => {
    return subpage === p ? activeBtn : null;
  };
  const abortControllersRef = useRef({});
  const abortAllFetches = () => {
    Object.values(abortControllersRef.current).forEach((controller, index) => {
      controller.abort();
    });
    abortControllersRef.current = {}; // Clear after aborting
  };
  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const onSetTableViewChange = (checked) => {
    dispatch(setAccountsTableView(checked));
  };

  useEffect(() => {
    setSubpage(location.pathname.includes("users") ? 0 : 1);
  }, [location]);

  useEffect(() => {
    if (!Boolean(accounts?.list)) return;
    let account = selectAccountById(accounts?.list, +id);

    if (account) {
      setActData(account);

      if (!!account.virtualAccount) {
        setVirtualAccount(1);
        dispatch(setParentAccount({ parentAccountName: "", parentAccountId: null }));

        let subAccountData = accounts?.list.filter((i) => i.parentAccount === account.id);
        let newData = subAccountData.map((i) => Object.assign({}, i, { partnerName: i.company, partnerId: i.id }));

        setPartnerData(newData);
      } else {
        setVirtualAccount(0);
        setPartnerData(account.parnterData);
      }
    }
  }, [accounts, navigate]);
  useEffect(() => {
    async function clearSpecificCache() {
      if ("caches" in window) {
        const cacheName = CONSTANT.cacheNameAccountImages;
        const deleted = await caches.delete(cacheName);
        if (deleted) {
          console.log(`${cacheName} cache cleared.`);
        } else {
          console.log(`${cacheName} cache not found.`);
        }
      }
    }
    window.addEventListener("beforeunload", clearSpecificCache);
    return () => window.removeEventListener("beforeunload", clearSpecificCache);
  }, []);

  const updateDataAndRows = (data) => {
    setShouldUpdate((st) => !st);
    dispatch(updImgTitle());
    refetch();
  };

  const handleHistoryAccountsItemClick = () => {
    navigate("/accounts");
  };
  const handleHistoryAccountsParentClick = () => {
    navigate("/accounts/" + origin.parentAccountId);
  };

  const bc = () => {
    //REWORK (temporary nav)
    return (
      <Box
        sx={{
          display: "flex",
          color: colors.lightBlue,
          cursor: "pointer",
          alignItems: "flex-start",
        }}
      >
        <Box id="accounts-breadcrumb-btn" onClick={handleHistoryAccountsItemClick} sx={{ marginRight: "4px" }}>
          Accounts /
        </Box>
        {origin?.parentAccountName && (
          <Box id="parent-breadcrumb-btn" onClick={handleHistoryAccountsParentClick} sx={{ marginRight: "4px" }}>
            {origin.parentAccountName} /
          </Box>
        )}
        <Box id="details-breadcrumb-btn" sx={{ marginRight: "4px", color: "#1BB2FF" }}>
          Details
        </Box>
      </Box>
    );
  };

  if (values.error) {
    return <Box>{values.error === 403 ? <ForbiddenResourceNotification /> : values.error}</Box>;
  }
  if (Object.keys(actData).length === 0)
    return (
      <LoaderWithProgress
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "75vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );

  if (isLoading || isFetching) {
    return (
      <LoaderWithProgress
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "75vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <Box>
      <Box sx={{ ...btnWrapper, position: "relative" }}>
        <Button
          id="account-tab-btn"
          sx={singleBtn}
          style={isActiveButton(1)}
          onClick={() => {
            //setSubpage(1);
            navigate(`/accounts/${id}`);
          }}
        >
          Account details
        </Button>
        <Button
          id="users-tab-btn"
          sx={singleBtn}
          style={isActiveButton(0)}
          // disabled={true}
          onClick={() => {
            //setSubpage(0);
            navigate(`/accounts/users/${id}`);
          }}
        >
          Users | {actData?.users.length}
        </Button>
      </Box>
      {/* <Breadcrumbs act_id={rec} /> */}
      <Box
        sx={{
          // padding: "0rem 1rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem ",
        }}
      >
        {bc()}
        {subpage === 1 ? (
          <>
            <AdminElement canAdmin={actData.canAdmin && loggedUser.accountId === CONSTANT.IIQ_ && !virtualAccount}>
              <Button
                id="manage-connections-btn"
                onClick={() => {
                  navigate("/accounts/connections/" + id);
                }}
              >
                Manage connections
              </Button>
            </AdminElement>

            {actData.status !== CONSTANT.ACCOUNT_STATUS.NOT_ACTIVE.value && (
              <AdminElement canAdmin={actData.canAdmin && virtualAccount}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Box sx={{ width: "256px" }}>
                    <SideBoardWrapper
                      id="assign-account"
                      type="virtual_account"
                      headerTitle="Add account to this Multi-account"
                      btnTitle="Assign account"
                      updateDataAndRows={updateDataAndRows}
                      value={{ ...actData, subAccountsData: partnerData, showUserSection: false }}
                    />
                  </Box>
                </Box>
              </AdminElement>
            )}
          </>
        ) : null}
        {subpage === 0 ? (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <TextField
              id="search-input"
              type="search"
              placeholder="Search user..."
              value={searchInput}
              onChange={handleSearchChange}
              sx={{
                width: "256px",
                fieldset: { borderColor: "#E0E2E7", borderRadius: "0.5rem" },
                paddingRight: "6px",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                styles: {
                  height: "42px !important",
                  padding: "0 !important",
                },
              }}
            />
            <AdminElement
              canAdmin={actData.canAdmin && actData.status !== CONSTANT.ACCOUNT_STATUS.NOT_ACTIVE.value}
            >
              <Box sx={{ width: "256px" }}>
                <SideBoardWrapper
                  id="add-user"
                  type="user"
                  headerTitle="Add new user to account"
                  btnTitle="Add new user"
                  value={actData}
                />
              </Box>
            </AdminElement>
          </Box>
        ) : null}
      </Box>
      {subpage === 1 ? (
        <Box sx={{ fontSize: "1.5rem" }}>
          <AccountDetails
            actData={actData}
            updateDataAndRows={updateDataAndRows}
            userManagementAdminPermission={actData.canAdmin}
            isVirtual={virtualAccount}
          />
        </Box>
      ) : null}
      {/* TODO: REWORK inner componets rendering*/}

      {/* rework */}
      {subpage === 1 && virtualAccount === 1 ? (
        <>
          <TableCardsToggleSwitch onChange={onSetTableViewChange} tableView={tableView} />
          {!tableView ? (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "auto auto auto",
                gap: "0.5rem",
              }}
            >
              {partnerData.map((i, ix) => {
                const abortController = new AbortController();
                const signal = abortController.signal;
                abortControllersRef.current[i.id] = abortController;
                return (
                  <ButtonBase
                    key={"buttonbase" + ix}
                    onClick={() => {
                      dispatch(
                        setParentAccount({ parentAccountName: actData.company, parentAccountId: actData.id })
                      );
                      abortAllFetches();
                      navigate(`/accounts/${i.id}`);
                    }}
                  >
                    <AccountCard d={i} signal={signal} />
                  </ButtonBase>
                );
              })}
            </Box>
          ) : (
            <AccountsTable filteredAccounts={partnerData} />
          )}
        </>
      ) : null}
      {subpage === 1 && virtualAccount === 0 ? (
        <>
          <TableCardsToggleSwitch onChange={onSetTableViewChange} tableView={tableView} />
          {!tableView ? (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "auto auto auto",
                gap: "0.5rem",
              }}
            >
              {partnerData.map((i, ix) => {
                return (
                  <Box key={"card" + ix} sx={{ fontSize: "1.5rem" }}>
                    <PartnerCard
                      d={{
                        ...i,
                        isAccountActive: actData.status !== CONSTANT.ACCOUNT_STATUS.NOT_ACTIVE.value,
                        canAdmin: actData.canAdmin,
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
          ) : (
            <PartnersTable partners={partnerData} />
          )}
        </>
      ) : null}
      {subpage === 0 ? (
        <Box>
          <AccountUsersPage
            actData={actData}
            usersData={actData.users}
            updateDataAndRows={updateDataAndRows}
            userManagementAdminPermission={actData.canAdmin}
            searchInput={searchInput}
          />
        </Box>
      ) : null}
    </Box>
  );
}
