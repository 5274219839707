import { createAsyncThunk } from "@reduxjs/toolkit";
import auth from "../../auth/auth-helper";
import { setSavedRawDataType } from "../../savedRawData/savedRawDataSlice";
import { CONSTANT } from "../../constants/constants";
import { getParametersConstancy } from "../../parametersConstancy/parametersContancyFetches";
const host = CONSTANT.path.host;

export const getReportByPlacement = createAsyncThunk(
  "report/getReportByPlacement",
  async (report, { rejectWithValue, dispatch, getState }) => {
    let { token, signal } = report;
    let currentState = getState();
    let jwt = auth.isAuthenticated();
    let ct = currentState.report.active_chart;
    let rt = currentState.report.active_range_date;
    let base = currentState.report.filterBase === "summary" ? "placement_id" : currentState.report.filterBase;
    let target = "profile_placement";
    let pidParam = "";
    let customDateParam = "";
    let isAdmin = jwt.data.user.scope.title === "admin" ? true : false;
    let skipSetBaseAsActive =
      "skipSetFilterBaseAsActive" in report && report.skipSetFilterBaseAsActive ? true : false;
    let skipInternalSearch = "skipInternalSearch" in report && report.skipInternalSearch ? true : false;

    if (jwt.data.user.scope.title === "admin") {
      if (!Boolean(currentState.role.partnersSelected)) {
        return {
          result: { data: [] },
          activeChart: ct,
          activeRangeDate: rt,
        };
      }
      target = "profile_placement_observe";
      pidParam = `&pid=${currentState.role.partnersSelected}`;
    }

    if ("ct" in report) ct = report.ct;
    if ("rt" in report) rt = report.rt;
    if ("setFilterBase" in report) base = report.setFilterBase === "summary" ? base : report.setFilterBase;
    if (rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
      customDateParam = `&cstm_start=${report.customDateRange.customDateStart}&cstm_end=${report.customDateRange.customDateEnd}`;
    }

    if (!skipInternalSearch && currentState.savedRawData[rt][base].length > 0) {
      let result = currentState.savedRawData[rt][base];
      let data = {
        result: { data: result },
        activeChart: ct,
        activeRangeDate: rt,
        skipSetBaseAsActive: skipSetBaseAsActive,
        customDateRange: report.customDateRange,
      };
      if ("setFilterBase" in report) data.filterBase = report.setFilterBase;
      return data;
    }

    try {
      let response = await fetch(
        `${host}/reports/${target}/${report.id}?rt=${rt}&ct=${ct}&b=${base}${pidParam}${customDateParam}`,
        {
          method: "GET",
          headers: {
            authorization: "Bearer " + token,
          },
          signal,
        }
      );

      if (!response.ok) {
        throw new Error("server can not get report");
      }

      const parametersConstancy = await getParametersConstancy(token);

      let result = await response.json();
      let data = {
        result,
        activeChart: ct,
        activeRangeDate: rt,
        filterBase: base,
        skipSetBaseAsActive,
        parametersConstancy,
        customDateRange: report.customDateRange,
      };

      dispatch(setSavedRawDataType(data));
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
