import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import auth from "./auth-helper";
import { useDispatch } from "react-redux";
import { setToken } from "../accounts/users/usersSlice";
import { CONSTANT } from "../constants/constants";

const useAuth0Token = () => {
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const dispatch = useDispatch();

  // Function to parse the JWT and extract the payload
  const parseJwt = (token: string) => {
    try {
      return JSON.parse(window.atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  // Function to set the timeout to refresh the token AFTER expiration
  const setTokenRefreshTimeout = (token: string) => {
    const decodedJwt = parseJwt(token);
    if (decodedJwt?.exp) {
      const expiresIn = decodedJwt.exp * 1000 - Date.now(); // Time remaining in ms
      // Set timeout to refresh token AFTER expiration
      const refreshTime = expiresIn + 50; // Refresh immediately after expiration
      if (refreshTime > 0) {
        setTimeout(() => {
          console.log("Token expired, refreshing...");
          fetchToken(); // Call fetchToken to refresh the token
        }, refreshTime);
      }
    }
  };

  // Fetch token silently if not expired
  const fetchToken = async () => {
    try {
      if (isAuthenticated) {
        const refreshedToken = await getAccessTokenSilently(); // Refresh the token
        const updatedLS = auth.isAuthenticated();
        updatedLS.data.accessToken = refreshedToken;
        dispatch(setToken(refreshedToken));
        localStorage.setItem("jwt", JSON.stringify(updatedLS));
        setTokenRefreshTimeout(refreshedToken); // Set timeout for the new token
      }
    } catch (err: any) {
      console.error("Error fetching access token:", err);
      if (err.message === "Login required") {
        console.warn("Session expired. Redirecting to login.");
        await logout({ logoutParams: { returnTo: CONSTANT.auth0.logout_uri, federated: true } });
      }
    }
  };

  // Run the token fetch process when the authentication status changes
  useEffect(() => {
    fetchToken();
  }, [isAuthenticated]);

  return null; // No need to return anything
};

export default useAuth0Token;
