import { CONSTANT } from "../constants/constants";

const auth = {
  isAuthenticated() {
    if (typeof window == "undefined") return false;

    if (localStorage.getItem("jwt")) return JSON.parse(localStorage.getItem("jwt"));
    else return false;
  },
  authenticate(jwt, cb) {
    if (typeof window !== "undefined") {
      localStorage.setItem("jwt", JSON.stringify(jwt));
    }
    cb();
  },
  isStoredSettings() {
    if (typeof window == "undefined") return false;

    if (localStorage.getItem(CONSTANT.localStorage.beAppSettings)) return true;
    else return false;
  },
  getStoredSettings() {
    return JSON.parse(localStorage.getItem(CONSTANT.localStorage.beAppSettings));
  },
  parseJwt(token) {
    try {
      return JSON.parse(window.atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  },
  isExpired(t) {
    if (t) {
      const decodedJwt = this.parseJwt(t);
      if (decodedJwt.exp * 1000 < Date.now()) {
        return true;
      }
      return false;
    }
    return true;
  },
  clearStorage(cb) {
    if (typeof window !== "undefined") {
      localStorage.removeItem("jwt");
      cb();
    }
  },
  clearJWT(cb) {
    if (typeof window !== "undefined") {
      localStorage.removeItem("jwt");
      cb();
    }
    //optional
    // logout().then((data) => {
    //   document.cookie = "t=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    // })
  },
  clearSettings(cb = () => {}) {
    if (typeof window !== "undefined") {
      localStorage.removeItem(CONSTANT.localStorage.beAppSettings);
      cb();
    }
  },
  isAdminRole(jwt = this.isAuthenticated()) {
    try {
      return jwt.data.user.scope.title === "admin" ? true : false;
    } catch (e) {
      return false;
    }
  },
  handleBusinessDetailsToggle(jwt = this.isAuthenticated()) {
    try {
      return jwt.data?.user.acctId === CONSTANT.IIQ_ ? true : false;
    } catch (e) {
      return false;
    }
  },
  getUserManagementModuleAdminPermission(jwt = this.isAuthenticated()) {
    try {
      return jwt.data.user.canCreateAccount;
    } catch (e) {
      return false;
    }
  },
  getPartnersName(jwt = this.isAuthenticated()) {
    try {
      return {
        name: jwt.data.user.prtnr,
        d_id: jwt.data.user.d_id,
        hourlyGrpService: jwt.data.user.hourlyGrpService,
      };
    } catch (e) {
      return false;
    }
  },
};

export default auth;
