import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUser, UsersState, originsMap } from "./interfaces";
import { loadFromSessionStorage, saveToSessionStorage } from "./utils";

const getValueFromLocalStorage = (propertyPath: string) => {
  const jwtData = localStorage.getItem("jwt");
  if (!jwtData) {
    return null;
  }

  try {
    const parsedJwtData = JSON.parse(jwtData);
    return propertyPath.split(".").reduce((acc, key) => acc?.[key], parsedJwtData) || null;
  } catch (e) {
    console.error("Error parsing JWT data from localStorage:", e);
    return null;
  }
};

const blankUser = {
  firstName: "",
  lastName: "",
  email: "",
  position: "",
  phone: "",
  avatarUrl: "",
  account_id: [],
  rls: [],
  canCreateAccount: false,
  modulesConfigurationId: 0,
  modulesVisibility: [],
  configurationAdministrator: 0,
  token: null,
  sessionId: "",
  isAbleSeeSensitiveData: false,
};

const initialState: UsersState = {
  isAvatrDialogOpen: false,
  editedUser: blankUser,
  loggedUser: {
    ...blankUser,
    avatarUrl: getValueFromLocalStorage("data.user.avatarUrl"),
    id: +getValueFromLocalStorage("data.user.id"),
    canCreateAccount: getValueFromLocalStorage("data.user.canCreateAccount"),
    firstName: getValueFromLocalStorage("data.user.firstName"),
    lastName: getValueFromLocalStorage("data.user.lastName"),
    account_id: getValueFromLocalStorage("data.user.acctId"),
    accountStatus: getValueFromLocalStorage("data.user.accountStatus"),
    trialEndDate: getValueFromLocalStorage("data.user.trialEndDate"),
    modulesVisibility: getValueFromLocalStorage("data.user.modulesVisibility"),
    configurationAdministrator: getValueFromLocalStorage("data.user.configurationAdministrator"),
    token: getValueFromLocalStorage("data.accessToken") || null,
    sessionId: getValueFromLocalStorage("data.sessionId") || null,
  },
  targetAccount: null,
  searchTerms: {
    accounts: "",
  },
  navigation: {
    origin: loadFromSessionStorage("navigationOrigin", originsMap.accounts),
  },
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setAvatrDialogOpen(state, action: PayloadAction<boolean>) {
      state.isAvatrDialogOpen = action.payload;
      //if (action.payload) state.editedUser = blankUser;
    },
    setEditedUser(state, action: PayloadAction<IUser>) {
      state.editedUser = action.payload;
    },
    setLoggedInUser(state, action: PayloadAction<IUser>) {
      state.loggedUser = action.payload;
    },
    setToken(state, action: PayloadAction<string>) {
      state.loggedUser.token = action.payload;
    },
    resetEditedUser(state) {
      state.editedUser = blankUser;
    },
    resetLogedInUser(state) {
      state.loggedUser = blankUser;
    },
    setTargetAccount(state, action) {
      state.targetAccount = action.payload;
    },
    setSearchTerms(state, action) {
      state.searchTerms = { ...state.searchTerms, ...action.payload };
    },
    setOrigin(state, action) {
      state.navigation.origin = originsMap[action.payload];
      saveToSessionStorage("navigationOrigin", originsMap[action.payload]);
    },
    setParentAccount(state, action) {
      state.navigation.origin = { ...state.navigation.origin, ...action.payload };
      saveToSessionStorage("navigationOrigin", state.navigation.origin);
    },
    resetSearchTerms(state, action) {
      state.searchTerms = { accounts: "" };
    },
  },
  extraReducers(builder) {},
});

export const {
  setAvatrDialogOpen,
  setEditedUser,
  resetEditedUser,
  setLoggedInUser,
  resetLogedInUser,
  setTargetAccount,
  setSearchTerms,
  resetSearchTerms,
  setOrigin,
  setParentAccount,
  setToken,
} = usersSlice.actions;

export default usersSlice.reducer;
