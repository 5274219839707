import { GridColDef } from "@mui/x-data-grid";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Box from "@mui/material/Box";
import { formatDate } from "../../utils";
// import UnassignRole from "./tableComponents/UnassignRole";
import EditRole from "./EditRole";
import { colors } from "../../theme";
import { addAlpha, nameToColor } from "../../core/performanceReports/advancedReport/table/tableColumnsModel";

type badgeStlOptions = {
  [key: string]: any;
};

let btnStl = {
  width: "32px",
  height: "32px",
  textAlign: "center",
  padding: "3px",
  borderRadius: "0.5rem",
  fontWeight: 600,
  fontSize: "1rem",
  cursor: "pointer",
  backgroundColor: "white",
  color: colors.mainDarkPrimary100,
  border: `1px solid ${colors.mainDarkPrimary10}`,
  "&:hover": {
    backgroundColor: "rgba(27, 178, 255, 0.15)",
    color: "secondary.mainDark",
    border: "1px solid transparent",
  },
};

let badgeStl: badgeStlOptions = {
  iiq: {
    color: "#fff",
    padding: "4px 12px",
    backgroundColor: "#0a4779",
    borderRadius: "6px",
  },
  virtual: {
    position: "relative",
    "&:after": {
      position: "absolute",
      content: '"Multi"',
      fontSize: "0.875rem",
      top: "-2px",
      right: "-75px",
      padding: "0.25rem 0.5rem",
      background: "orchid",
      borderRadius: "6px",
      color: "#fff",
      fontWweight: 700,
    },
  },
};

export const defaultColumns = (props: { actionsProps: any }): GridColDef[] => [
  {
    field: "name",
    headerName: "Role name",
    headerAlign: "left",
    align: "left",
    renderCell: (params: any) => {
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              fontWeight: 600,
            }}
            id="role-name"
          >
            {params.row.name}
          </Box>
        </Box>
      );
    },
    flex: 1,
  },
  {
    field: "accountName",
    headerName: "Account",
    flex: 1,
    align: "center",

    renderCell: (params: any) => {
      let stl = !!params.row.styled ? badgeStl[params.row.styled] : {};
      return (
        <Box
          onClick={() => props.actionsProps.handleAccountCellClick(params)}
          sx={{ cursor: "pointer", display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              marginRight: "0px",
              padding: "4px 12px",
              borderRadius: "6px",
              background: addAlpha(nameToColor(params.row.accountName), 0.3),
              ...stl,
            }}
            id="account-name"
          >
            {params.row.accountName}
          </Box>
        </Box>
      );
    },
  },
  {
    field: "roleDescription",
    headerName: "Description",
    flex: 1,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "usersAmountPerRole",
    headerName: "Amount of users",
    type: "number",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
    renderCell: (params: any) => {
      return (
        <Box
          id="user-amount"
          onClick={() => props.actionsProps.handleAmountOfUsersCellClick(params)}
          sx={{ cursor: "pointer", display: "flex", justifyContent: "center" }}
        >
          {params.row.usersAmountPerRole}
        </Box>
      );
    },
  },
  {
    field: "dateCreation",
    headerName: "Date of creation",
    flex: 1,
    align: "center",

    valueFormatter: (props) => {
      return formatDate(new Date(props.value)).fullFormat;
    },
  },
  {
    field: "createdBy",
    headerName: "Created by",
    flex: 1,
    align: "center",
  },
  {
    field: "Actions",
    headerName: "Actions",
    align: "center",

    renderCell: (params: any) => {
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{}} id="edit-role-btn">
            <EditRole {...params} />
          </Box>
          <Box
            onClick={() => props.actionsProps.handleViewDetailsClick(params)}
            sx={{ ...btnStl, marginRight: "4px", display: "flex", justifyContent: "center", alignItems: "center" }}
            id="view-role-btn"
          >
            <RemoveRedEyeOutlinedIcon sx={{ fontSize: "1rem", marginTop: "2px", color: "grey" }} />
          </Box>
          {/* <Box
        sx={{...btnStl, padding:0}}
        id="test_id_act_btn_del"
      >
        <UnassignRole 
        {...params}
         userIds={props.actionsProps.getUserId()}
         refetchUserById={props.actionsProps.refetchUserById}
         />
        <DeleteOutlinedIcon sx={{ fontSize: "1rem", marginTop: "2px" }} />
      </Box> */}
        </Box>
      );
    },
  },
];
