import { CONSTANT } from "../../constants/constants";
import { ISession } from "../../monitoring/monitoringApi";

export interface IRmpModulesConfiguration {
  id: number;
  name: string;
  should_hide_sensitive_data: boolean;
  accept_user_override: boolean;
  accept_parent_override: boolean;
  created_by?: number | null; // Optional since it has a default value of NULL
  created_at?: Date | null; // Optional since it has a default value of NULL
}

export interface IUserExtraProps {
  id?: number;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  active?: number;
  createdAt?: string;
  lastLogin?: string;
  sessionId?: string;
  position?: string;
  avatarUrl?: string;
  account_id: any;
  rls: any;
  canCreateAccount: boolean;
  canAdminPersonalData?: boolean;
  canAdminAccount?: boolean;
  sessions: ISession[];
}

export interface IUser {
  id?: number;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  active?: number;
  createdAt?: string;
  lastLogin?: string;
  position?: string;
  avatarUrl?: string;
  account_id: any;
  rls: any;
  canCreateAccount: boolean;
  modulesConfigurationId: number;
  accountStatus?: number;
  trialEndDate?: string;
  modulesVisibility: number[];
  configurationAdministrator?: number;
  token: string | null;
  sessionId: string;
  isAbleSeeSensitiveData: boolean;
}

export interface INavigationData {
  origin: IOrigin;
}

export interface IOrigin {
  name: OriginNames;
  path: string;
  breadcrumpsName: string;
  parentAccountName?: string;
  parentAccountId?: string;
}
export enum OriginNames {
  Blank = "blank",
  Accounts = "accounts",
  Users = "Users",
}

export const originsMap: { [key: string]: IOrigin } = {
  blank: {
    path: CONSTANT.PAGES.ROOT.path,
    breadcrumpsName: "",
    name: OriginNames.Blank,
  },
  accounts: {
    path: CONSTANT.PAGES.ACCOUNTS.path,
    breadcrumpsName: "Accounts",
    name: OriginNames.Accounts,
  },
  users: {
    path: CONSTANT.PAGES.USERS.path,
    breadcrumpsName: "Users",
    name: OriginNames.Users,
  },
  // Add more origins as needed
};

export interface UsersState {
  isAvatrDialogOpen: boolean;
  editedUser: IUser;
  loggedUser: IUser;
  targetAccount?: any;
  searchTerms?: any;
  navigation: INavigationData;
}
