import React from "react";
import { Box, Typography, Chip, SxProps } from "@mui/material";
import { colors } from "../theme";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";

export enum ChangeType {
  Increase = "increase",
  Decrease = "decrease",
}

export enum TimeRange {
  P7D = "7 days",
  P14D = "14 days",
  P30D = "30 days",
}

export enum MetricNames {
  ActiveUsers = "Active users",
  TotalUsers = "Total users",
  SessionDuration = "Session duration",
  Requests = "Requests",
}

const metricStyles: Record<MetricNames, { textColor: string; backgroundColor: string }> = {
  [MetricNames.ActiveUsers]: { textColor: colors.successIcon, backgroundColor: colors.successBackground },
  [MetricNames.TotalUsers]: { textColor: colors.contentInfo, backgroundColor: colors.blue50 },
  [MetricNames.SessionDuration]: { textColor: colors.foreground, backgroundColor: colors.navy50 },
  [MetricNames.Requests]: { textColor: colors.yellow900, backgroundColor: colors.yellow50 },
};

export interface DashboardCardProps {
  metricName: MetricNames;
  timeRange: TimeRange;
  value: number | string;
  subValue?: number;
  subLabel?: string;
  change?: number;
  changeType?: ChangeType;
}

export const cardSx: SxProps = {
  width: "100%",
  height: 116,
  p: "16px",
  backgroundColor: "white",
  borderRadius: "24px",
  border: `1px solid ${colors.borderDefault}`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: 2,
};

const DashboardCard: React.FC<DashboardCardProps> = ({
  metricName,
  timeRange,
  value,
  change = 0,
  changeType,
  subValue,
  subLabel,
}) => {
  const changeTextColor = changeType === ChangeType.Increase ? "#07883A" : "#FF4D4F";
  const changeBackgroundColor = changeType === ChangeType.Increase ? "#E7F9EE" : "#FDECEF";
  const { textColor, backgroundColor: labelBackgroundColor } = metricStyles[metricName];

  return (
    <Box sx={cardSx}>
      <Box
        sx={{
          alignSelf: "stretch",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            alignSelf: "stretch",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Chip
            label={
              <Typography variant="bodySmallMedium" color={textColor}>
                {metricName}
              </Typography>
            }
            sx={{
              backgroundColor: labelBackgroundColor,
              borderRadius: "8px",
              px: "4px",
              py: "2px",
            }}
          />
          <Typography variant="bodySmallMedium">to previous {timeRange}</Typography>
        </Box>
        <Box
          sx={{
            alignSelf: "stretch",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="bodySmallMedium">{subLabel}</Typography>
          <Typography variant="bodySmallMedium">{subValue}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            gap: 2,
          }}
        >
          <Typography variant="h1XLargeDark600">{value}</Typography>
          {changeType && (
            <Chip
              label={
                <Typography variant="bodySmallMedium" color={changeTextColor}>
                  {change > 0 ? `+${change}` : change}
                </Typography>
              }
              sx={{
                backgroundColor: changeBackgroundColor,
                borderRadius: "8px",
                px: "4px",
                py: "2px",
              }}
              icon={
                changeType === ChangeType.Increase ? (
                  <TrendingUpIcon style={{ color: changeTextColor }} />
                ) : (
                  <TrendingDownIcon style={{ color: changeTextColor }} />
                )
              }
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardCard;
