import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import { buttonSx } from "../../core/performanceReports/advancedReport/styles";
import Loader from "../../components/Loader";
import { updateUserRole } from "../api/userDetails-api";
import RoleMenu from "../helpers/RoleMenu";
import { boardWrapperStl, stl } from "./boardStl";
import { useGetAccountsQuery, useGetUserByIdQuery } from "../accountsApi";

export default function EditRoleBoardHandler(props) {
  const { refetch: refetchUserById } = useGetUserByIdQuery(`${props.value.userData.id}`);
  const [showLoader, setShowLoader] = useState(false);
  const [formValues, setFormValues] = useState({
    role: {
      value: props.value.userData.account_id.roles.length ? props.value.userData.account_id.roles[0].id : "",
      error: false,
      errorMessage: "Users' role should be provided",
      validationFunction: [(val) => !!val.trim().length],
    },
  });
  const { data: accounts, isFetching, isLoading, refetch } = useGetAccountsQuery();

  const handleleRoleSelection = (roleId) => {
    setFormValues({
      ...formValues,
      role: { ...formValues["role"], value: roleId, error: false },
    });
  };

  const handleRetrieve = () => {
    const formFields = Object.keys(formValues);
    let newFormValues = { ...formValues };
    let errors = [];

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      const currentValueStr = formValues[currentField].value.toString();

      for (let validator of formValues[currentField].validationFunction) {
        if (!validator(currentValueStr)) {
          newFormValues = {
            ...newFormValues,
            [currentField]: { ...newFormValues[currentField], error: true },
          };
          errors.push({ ...newFormValues[currentField], error: true });
        }
      }
    }

    if (!!errors.length) {
      setFormValues(newFormValues);
      return;
    }

    setShowLoader(true);

    let data = {
      id: props.value.userData.id,
      role: formValues.role.value,
      target: props.value.userData.account_id.id,
    };

    updateUserRole(data, props.value.userData.account_id.id).then((data) => {
      setShowLoader(false);
      refetch();
      refetchUserById();
      if (data.error) {
        console.log(data.error);
        alert("Action failed, something went wrong... " + data.error);
      } else {
        props.onClose();
      }
    });
  };

  if (showLoader) return <Loader sx={{ height: "80vh" }} />;

  //exclude already assigned roles
  // let availiableToAsssign = props.value.userData.account_id.roles.filter((i) => !props.value.rls.includes(i.id));

  return (
    <div id="change-role-popup">
      <div style={boardWrapperStl}>
        {/* Choose role */}
        <Box sx={{ ...stl, border: formValues.role.error ? "1px solid red" : null, borderRadius: "4px" }}>
          <RoleMenu
            onSelect={handleleRoleSelection}
            accountRoles={props.value.userData.account_id.roles}
            userRoles={props.value.userData.rls}
          />
        </Box>
      </div>
      <div style={{ display: "flex", justifyContent: "center", position: "relative" }}>
        <Button id="save-btn" sx={buttonSx} onClick={handleRetrieve}>
          Save changes
        </Button>
      </div>
    </div>
  );
}
