import React, { useState } from "react";
import { useDispatch } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { BasicTooltip } from "../BasicTooltip/BasicTooltip";
import auth from "../../auth/auth-helper";
import {
  resetSummaryCompleteDate,
  resetRawPctAndRawCurrencyIndicators,
  resetStatusWidgetProcessing,
  resetCurrencyPct,
} from "../../report/reportSlice";
import { resetSavedRawDataState } from "../../savedRawData/savedRawDataSlice";
import { resetSavedRawDataPreviousPeriodState } from "../../savedRawDataPreviousPeriod/savedRawDataPreviousPeriodSlice";
import { resetPartnersList, resetRolesState } from "../../role/roleSlice";
import { resetSummaryChartRecords } from "../../summary/summarySlice";
import { setReminderActivated } from "../../chartSelector/chartSelectorSlice";
import {
  resetMainTableData,
  resetTimeRangeAndSelectionBase,
  resetSelectionBaseRawData,
  setGetStatsBody,
  resetVRTData,
} from "../../core/performanceReports/advancedReport/advancedReportSlice";
import { accountsApi } from "../../accounts/accountsApi";
import { resetLogedInUser, resetSearchTerms } from "../../accounts/users/usersSlice";
import { storeParametersConstancy } from "../../parametersConstancy/parametersContancyFetches";
import { useAuth0 } from "@auth0/auth0-react";
import { CONSTANT } from "../../constants/constants";
import { resetAllParameters } from "../../parametersConstancy/platformParametersSlice";
import { logoutApi } from "../../auth/auth-api";

const stl = {
  descriptionLine: {
    textAlign: "left",
    color: "rgb(1, 45, 76)",
    fontSize: "14px",
    margin: "10px 0px 16px",
    padding: "10px",
    backgroundColor: "rgba(27, 178, 255, 0.15)",
    borderRadius: "5px",
  },
};

export default function LogoutPopup({ renderButton, showConfirmPopup }) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { logout, getAccessTokenSilently } = useAuth0();

  const clickButton = async () => {
    if (showConfirmPopup) {
      setOpen(true);
    } else {
      logoutFunction();
    }
  };
  const handleRequestClose = () => {
    setOpen(false);
  };

  const logoutFunction = async () => {
    // Store parameters constancy and Reset redux store
    const token = await getAccessTokenSilently();
    dispatch(storeParametersConstancy())
      .unwrap()
      .then(async () => {
        dispatch(resetLogedInUser());
        dispatch(resetSummaryCompleteDate());
        dispatch(resetSavedRawDataState());
        dispatch(resetSavedRawDataPreviousPeriodState());
        dispatch(resetRawPctAndRawCurrencyIndicators());
        dispatch(resetPartnersList());
        dispatch(resetSummaryChartRecords());
        dispatch(resetStatusWidgetProcessing());
        dispatch(setReminderActivated(false));
        dispatch(resetMainTableData());
        dispatch(resetVRTData());
        dispatch(resetTimeRangeAndSelectionBase());
        dispatch(resetSelectionBaseRawData());
        dispatch(resetRolesState());
        dispatch(resetSearchTerms({}));
        dispatch(setGetStatsBody(""));
        dispatch(resetCurrencyPct());
        dispatch(accountsApi.util.resetApiState());
        dispatch(resetAllParameters());
        auth.clearJWT(() => console.log("Logout"));
        await logoutApi(token);
        await logout({ logoutParams: { returnTo: CONSTANT.auth0.logout_uri, federated: true } });
      });
  };
  return (
    <Box>
      {renderButton ? (
        <BasicTooltip tootitpText={"Logout IIQ monitoring system"}>
          <IconButton aria-label="Delete" onClick={clickButton} color="secondary">
            <LogoutIcon />
          </IconButton>
        </BasicTooltip>
      ) : (
        <Box onClick={clickButton}>Logout</Box>
      )}

      <Dialog
        sx={{
          ".MuiPaper-root": {
            margin: 0,
            width: 420,
            padding: "26px",
            textAlign: "center",
            borderRadius: "1.4rem",
            boxShadow: "0 .4rem .8rem 0 rgba(0, 56, 93, .1)",
          },
        }}
        open={open}
        onClose={handleRequestClose}
      >
        <DialogTitle sx={{ fontSize: "1.25rem" }}>{"IIQ Bid Enhacement monitoring system"}</DialogTitle>
        <div style={{ ...stl.descriptionLine, fontSize: "1rem" }}>Please, confirm logout...</div>
        <DialogActions>
          <Button onClick={handleRequestClose} color="primary">
            Cancel
          </Button>
          <Button onClick={logoutFunction} color="secondary" autoFocus="autoFocus">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
