import React from "react";
import { Box, Typography, Chip, SxProps } from "@mui/material";
import { cardSx, TimeRange } from "./DashboardCard";
import { IMostActiveUsers } from "./utils";
import { colors } from "../theme";

export interface ActiveUsersCardProps {
  timeRange: TimeRange;
  mostActiveUserSessions: IMostActiveUsers[];
}

const ActiveUsersCard: React.FC<ActiveUsersCardProps> = ({ timeRange, mostActiveUserSessions }) => {
  return (
    <Box sx={{ ...cardSx, width: "100%", height: "100%", gap: "16px" }}>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
        <Chip
          label={
            <Typography variant="bodySmallMedium" color={colors.purpleDark}>
              Most active users
            </Typography>
          }
          sx={{
            backgroundColor: colors.purpleLight,
            borderRadius: "8px",
            px: "4px",
            py: "2px",
          }}
        />
        <Typography variant="bodySmallMedium">to previous {timeRange}</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflowY: "auto",
          height: "100%",
          paddingRight: "8px",
        }}
      >
        {mostActiveUserSessions.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              gap: "8px",
            }}
          >
            <Typography variant="bodyMedium" color={colors.textBaseTertiary}>{`${item.userName}`}</Typography>
            <Typography
              variant="bodyMedium"
              color={colors.textBaseTertiary}
            >{`${item.sessions} sessions`}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ActiveUsersCard;
