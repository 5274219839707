import { setLoggedInUser } from "../accounts/users/usersSlice";
import { CONSTANT } from "../constants/constants";
import { setupParameters } from "../parametersConstancy/parametersConstancyUtils";
const SERVER_URL = CONSTANT.path.host;

export const getReactAppVersion = async () => {
  try {
    let response = await fetch(`${SERVER_URL}/health/reactAppVersion`, {
      method: "GET",
    });
    return response.json();
  } catch (err) {
    console.log(err);
  }
};

export const loginApi = async (token) => {
  try {
    let response = await fetch(`${SERVER_URL}/auth/login`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.json();
  } catch (err) {
    console.log(err);
  }
};

export const logoutApi = async (token) => {
  try {
    let response = await fetch(`${SERVER_URL}/auth/logout`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Error logout");
    }
    return;
  } catch (err) {
    console.log(err);
  }
};

const getUserMetadata = async (token) => {
  try {
    let response = await fetch(`${SERVER_URL}/auth/user_metadata`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const userMetadata = await response.json();
      localStorage.setItem("userEmail", userMetadata.data.user.email); // Save email on successful login
      return userMetadata.data.user;
    }
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};

const getUserMetadataByToken = async (
  token,
  dispatch,
  throwErrorOnFail = false,
  navigate,
  skipSetupParametersConstancy = false
) => {
  try {
    const userData = await getUserMetadata(token);
    if (!userData) throw new Error("User not found");

    dispatch(
      setLoggedInUser({
        avatarUrl: userData.avatarUrl,
        id: userData.id,
        canCreateAccount: userData.canCreateAccount,
        firstName: userData.firstName,
        lastName: userData.lastName,
        account_id: userData.acctId,
        accountStatus: userData?.accountStatus,
        trialEndDate: userData?.trialEndDate,
        accountName: userData?.accountName,
        modulesVisibility: userData?.modulesVisibility,
        configurationAdministrator: userData?.configurationAdministrator,
        token: token,
        sessionId: userData?.sessionId,
        isAbleSeeSensitiveData: Boolean(userData.isAbleSeeSensitiveData),
      })
    );
    const parameters = userData.parametersConstancy;
    delete userData.parametersConstancy;
    const localStorageObject = {
      data: {
        user: userData,
        accessToken: token,
      },
    };
    localStorage.setItem("jwt", JSON.stringify(localStorageObject));
    if (!skipSetupParametersConstancy) {
      await setupParameters(parameters, dispatch);
    }
    if (navigate) {
      await loginApi(token);
      navigate(parameters?.commonUserPlatformParameters?.currentPage?.path || CONSTANT.PAGES.ROOT.path, {
        state: {
          userData: userData,
        },
      });
    }
  } catch (error) {
    console.error(error);
    if (throwErrorOnFail) throw new Error(error);
  }
};

export { getUserMetadata, getUserMetadataByToken };
