import { createAsyncThunk } from "@reduxjs/toolkit";
import { setSavedRawDataType } from "../../savedRawData/savedRawDataSlice";
import auth from "../../auth/auth-helper";
import { CONSTANT } from "../../constants/constants";
import { checkVersion } from "../../infrastructure/infrastructureHelpers";
import { getParametersConstancy } from "../../parametersConstancy/parametersContancyFetches";
import { transformColumnBaseToRowBase } from "../../core/performanceReports/advancedReport/utils";
import { setGetStatsBody } from "../../core/performanceReports/advancedReport/advancedReportSlice";

const host = CONSTANT.path.host;

export const getReport = createAsyncThunk(
  "report/getReport",
  async (report, { rejectWithValue, dispatch, getState }) => {
    let { token, signal } = report;
    let currentState = getState();
    let ct = currentState.report.active_chart;
    let rt = report.rt;
    let customDateRange = report.customDateRange;
    let dgm =
      currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateGroupingMode !==
      CONSTANT.DATE_GROUP_MODES.HOUR
        ? currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateGroupingMode
        : CONSTANT.DATE_GROUP_MODES.DAY;
    let base = currentState.report.filterBase === "summary" ? "client_type" : currentState.report.filterBase;
    let target = "profile";
    let pidParam = "";
    let customDateParam = "";
    let skipSetBaseAsActive =
      "skipSetFilterBaseAsActive" in report && report.skipSetFilterBaseAsActive ? true : false;
    let skipInternalSearch = "skipInternalSearch" in report && report.skipInternalSearch ? true : false;
    dispatch(setGetStatsBody(""));

    if (auth.isAdminRole()) {
      if (!Boolean(currentState.role.partnersSelected.length)) {
        return {
          result: { data: [] },
          activeChart: ct,
          activeRangeDate: rt,
        };
      }
      target = "profile_observe";
      let pids = currentState.role.partnersSelected.map((p) => p.dummy_id).join(",");
      pidParam = `&pid=${pids}`;
      // pidParam = `&pid=${currentState.role.partnersSelected}`;
    }
    if ("ct" in report) ct = report.ct;
    if ("setFilterBase" in report) base = report.setFilterBase === "summary" ? base : report.setFilterBase;
    if (rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
      customDateParam = `&cstm_start=${customDateRange.customDateStart}&cstm_end=${customDateRange.customDateEnd}`;
    }

    const parametersConstancy = await getParametersConstancy(token);
    let temporaryStoredUnits;
    const changedChartBaseUnits =
      currentState.platformParameters.changedParameters?.overviewParameters?.chartBaseUnits;
    if (changedChartBaseUnits) {
      temporaryStoredUnits = changedChartBaseUnits[base]?.unit || null;
    }

    if (!skipInternalSearch && currentState.savedRawData[rt] && currentState.savedRawData[rt][base]?.length > 0) {
      let result = currentState.savedRawData[rt][base];
      let data = {
        result: { data: result },
        activeChart: ct,
        activeRangeDate: rt,
        dateGroupingMode: dgm,
        skipSetBaseAsActive: skipSetBaseAsActive,
        settingsStrictMode: currentState.report.settingsStrictMode,
        parametersConstancy,
        temporaryStoredUnits,
        reportFilters: currentState.advancedReport.filters,
        currentFilterBase: currentState.report.filterBase,
      };

      if ("setFilterBase" in report) {
        data.filterBase = report.setFilterBase;

        // skipRefreshUints - false
        // save previous units set when switching between chart types (rev lift/ cpm lift/ fill rate lift)
        // within same base and same time range
        if (
          currentState.report.filterBase === report.setFilterBase &&
          currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateRange
            .value === rt
        )
          data.skipRefreshUints = false;
        if ("skipRefreshUints" in report) data.skipRefreshUints = report.skipRefreshUints;
      }

      if (rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
        data.customDateRange = customDateRange;
      }

      return data;
    }

    try {
      let response = await fetch(
        `${host}/reports/${target}/${report.id}?rt=${rt}&ct=${ct}&b=${base}&dgm=${dgm}${pidParam}${customDateParam}`,
        {
          method: "GET",
          headers: {
            authorization: "Bearer " + token,
          },
          signal,
        }
      );

      if (!response.ok) {
        if (response.status === 503) {
          throw new Error("service_unavailable");
        } else if (response.status === 403) {
          throw new Error("forbidden_resource");
        } else {
          throw new Error("server can not get report");
        }
      }

      let result = {};
      const responseJSON = await response.json();
      result.data = transformColumnBaseToRowBase(responseJSON.data);
      result.reactAppVersion = responseJSON.reactAppVersion;
      checkVersion(result.reactAppVersion);

      const rangeTypeConstancy = currentState.platformParameters.currentPlatformParameters.rangeType;

      let data = {
        result,
        activeChart: ct,
        activeRangeDate: rt,
        dateGroupingMode: dgm,
        filterBase: base,
        skipSetBaseAsActive,
        settingsStrictMode: currentState.report.settingsStrictMode,
        rangeTypeConstancy,
        parametersConstancy,
        temporaryStoredUnits,
        reportFilters: currentState.advancedReport.filters,
        currentFilterBase: currentState.report.filterBase,
      };

      if ("skipRefreshUints" in report) data.skipRefreshUints = report.skipRefreshUints;
      if (rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
        data.customDateRange = customDateRange;
      }
      dispatch(setSavedRawDataType(data));

      return data;
    } catch (error) {
      console.log("err", error);
      return rejectWithValue(error.message);
    }
  }
);
