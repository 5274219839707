import { useState, useEffect } from "react";
import { Button, Box, Divider, Typography, Switch, Popper, ClickAwayListener } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getPartnersList,
  setCurrentMultiselectGroup,
  setIsReportingPartnersSelected,
  applyPartners,
} from "../../role/roleSlice";
import { CONSTANT } from "../../constants/constants";
import { MultiselectGroupButton } from "../Button/Button";
import { colors } from "../../theme";
import FilterGroupDialogForm, {
  DialogTypes,
} from "../../core/performanceReports/advancedReport/filtersBoard/FilterGroupDialogForm";
import DropdownButton from "../Button/DropdownButton";
import { defaultPopperStyle } from "../Dropdown/Dropdown";
import GroupFilterHandler from "../../core/performanceReports/advancedReport/filtersBoard/GroupFilterHandler";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGetFilterGroupsQuery } from "../../parametersConstancy/parametersContancyFetches";

export const partnerDropdownTextFieldStyle = {
  marginTop: 0,
  "& .MuiOutlinedInput-root": {
    height: "48px",
    borderRadius: "18px",
    "& fieldset": {
      border: `none`,
    },
    "&:hover fieldset": {
      border: `none`,
    },
  },
  "& .MuiInputBase-input": {
    height: "48px",
    paddingLeft: "4px",
    fontSize: "16px",
    "&::placeholder": {
      opacity: 0.3,
    },
  },
  "& .MuiInputAdornment-root": {
    fontSize: "24px", // Set icon size
  },
};

export default function SelectMultiLabelsWrapper() {
  const { isReportingPartnersSelected } = useSelector((state) => state.role);

  const { data: filterGroupsRaw } = useGetFilterGroupsQuery();
  const filterGroupsPartner = filterGroupsRaw?.filter(
    (group) => group.type === CONSTANT.FILTER_GROUP_TYPE.PARTNER
  );
  const { partnersList, currentMultiselectGroup, partnersSelected } = useSelector((state) => state.role);
  const abortController = new AbortController();
  const signal = abortController.signal;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogActionsType, setDialogActionsType] = useState("");
  const [title, setTitle] = useState("Select partners");
  const [groupToEdit, setGroupToEdit] = useState({
    name: "",
    type: "partner",
    partners: [partnersList.filter((p) => p.isSelected)],
  });
  const { loggedUser } = useSelector((state) => state.users);

  const [partnersUpdatedValues, setPartnersUpdatedValues] = useState({
    [CONSTANT.REPORTS.PARTNERS.base]: partnersList.filter((p) => p.isSelected),
  });
  const [partnersUpdatedOptions, setPartnersUpdatedOptions] = useState({
    [CONSTANT.REPORTS.PARTNERS.base]: partnersList.filter((p) => !p.isSelected),
  });

  const [expanded, setExpanded] = useState(false);
  const handleExpand = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };
  useEffect(() => {
    setGroupToEdit((g) => {
      return { ...g, name: "", partners: partnersUpdatedValues[CONSTANT.REPORTS.PARTNERS.base] };
    });
  }, [partnersUpdatedValues]);

  const handleClickOpenFilterGroupDialogForm = (dialogActionsType) => {
    setDialogActionsType(dialogActionsType);
    setOpen(false);
    setOpenDialog(true);
  };
  useEffect(() => {
    setPartnersUpdatedValues({
      [CONSTANT.REPORTS.PARTNERS.base]: partnersList.filter((p) => p.isSelected),
    });
    setPartnersUpdatedOptions({
      [CONSTANT.REPORTS.PARTNERS.base]: partnersList.filter((p) => !p.isSelected),
    });
  }, [partnersList]);

  useEffect(() => {
    if (currentMultiselectGroup) {
      setTitle(`${currentMultiselectGroup.name} group selected`);
    } else {
      const numberOfPartners = partnersUpdatedValues[CONSTANT.REPORTS.PARTNERS.base].length;
      switch (numberOfPartners) {
        case 0: {
          setTitle("Select partners");
          break;
        }
        case 1: {
          setTitle("1 partner selected");
          break;
        }
        default: {
          setTitle(`${numberOfPartners} partners selected`);
        }
      }
    }
  }, [partnersSelected, currentMultiselectGroup, partnersUpdatedValues]);

  useEffect(() => {
    if (partnersList.length === 0 && loggedUser.token) {
      dispatch(
        getPartnersList({
          signal,
        })
      );
    }
  }, []);

  const handleApply = () => {
    dispatch(applyPartners(partnersUpdatedValues[CONSTANT.REPORTS.PARTNERS.base]));
    dispatch(setCurrentMultiselectGroup(null));
    setOpen(false);
  };

  const handleKeyUp = (event) => {
    if (open && event.key === "Enter") {
      handleApply();
    }
  };
  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [partnersUpdatedValues]);

  const handleClear = (event) => {
    event.stopPropagation();
    setPartnersUpdatedValues({
      [CONSTANT.REPORTS.PARTNERS.base]: [],
    });
    setPartnersUpdatedOptions({
      [CONSTANT.REPORTS.PARTNERS.base]: partnersList,
    });
    dispatch(setCurrentMultiselectGroup(null));
  };
  const handleButtonClick = (event) => {
    if (open) {
      setOpen(false);
      setAnchorEl(null);
      setPartnersUpdatedValues({
        [CONSTANT.REPORTS.PARTNERS.base]: partnersList.filter((p) => p.isSelected),
      });
      setPartnersUpdatedOptions({
        [CONSTANT.REPORTS.PARTNERS.base]: partnersList.filter((p) => !p.isSelected),
      });
    }
    setOpen(!open);
    setAnchorEl(event.currentTarget);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (open) {
          setOpen(false);
          setAnchorEl(null);
          setPartnersUpdatedValues({
            [CONSTANT.REPORTS.PARTNERS.base]: partnersList.filter((p) => p.isSelected),
          });
          setPartnersUpdatedOptions({
            [CONSTANT.REPORTS.PARTNERS.base]: partnersList.filter((p) => !p.isSelected),
          });
        }
      }}
    >
      <div>
        <DropdownButton
          title={title}
          id="partners-select"
          variant="filter"
          dropdownOpened={open}
          isDisabled={false}
          buttonCallback={handleButtonClick}
          clearIconCallback={handleClear}
          shouldBeSelected={true}
          base={CONSTANT.REPORTS.PARTNERS.base}
          selectedItemsLength={partnersUpdatedValues[CONSTANT.REPORTS.PARTNERS.base].length}
        />

        <Popper
          id="partners-popper"
          open={open}
          anchorEl={anchorEl}
          disablePortal={false}
          placement="bottom-end"
          sx={defaultPopperStyle}
          modifiers={[
            {
              name: "flip",
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: "document",
              },
            },
          ]}
        >
          <GroupFilterHandler
            base={CONSTANT.REPORTS.PARTNERS.base}
            options={partnersUpdatedOptions}
            values={partnersUpdatedValues}
            setFiltersOptions={(newOptions) => {
              setPartnersUpdatedOptions(newOptions);
              //dispatch(setCurrentMultiselectGroup(null));
            }}
            setFiltersValues={setPartnersUpdatedValues}
            isReadOnlyView={false}
            withPartners={true}
            selectAllEnabled={partnersList.length > 1}
            actionItemsTop={
              <Box
                sx={{
                  padding: "0 8px 0 8px",
                }}
              >
                <Divider></Divider>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "8px",
                    border: "none",
                  }}
                >
                  <Typography variant="body1Small400">Only reporting partners</Typography>
                  <Switch
                    checked={isReportingPartnersSelected}
                    onChange={(e) => {
                      dispatch(setIsReportingPartnersSelected(e.target.checked));
                    }}
                    inputProps={{}}
                    id="only-reported-partners-switch"
                    // disabled={true}
                  />
                </Box>
                {filterGroupsPartner?.length > 0 && (
                  <>
                    <Accordion
                      sx={{ border: "none", "& fieldset": { border: "none" } }}
                      expanded={expanded}
                      onChange={handleExpand}
                      elevation={0}
                      disableGutters
                    >
                      <AccordionSummary
                        sx={{ padding: "0 8px 0 8px", border: "none" }}
                        expandIcon={<ExpandMoreIcon />}
                        id="select-partner-modal-custom-group-accordion"
                      >
                        <Typography variant="body1Small600">{`Custom group (${filterGroupsPartner?.length})`}</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: "0 8px 0 8px" }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "4px",
                            overflow: "auto",
                            maxHeight: "112px", // Adjust this based on the actual button height
                            flexGrow: 0,
                            flexShrink: 0,
                          }}
                        >
                          {filterGroupsPartner?.map((group) => (
                            <MultiselectGroupButton
                              partnersString={group.partners.map((p) => p.name).join(", ")}
                              isActive={group.id === currentMultiselectGroup?.id}
                              name={group.name}
                              groupId={group.id}
                              key={group.id}
                              onClick={() => {
                                dispatch(setCurrentMultiselectGroup(group));
                                setOpen(false);
                              }}
                              onEdit={() => {
                                setGroupToEdit(group);
                                handleClickOpenFilterGroupDialogForm(DialogTypes.EDIT);
                              }}
                              id="custom-group-item"
                            />
                          ))}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </>
                )}
              </Box>
            }
            additionalFilterIsReporting={isReportingPartnersSelected}
            textFieldStyle={partnerDropdownTextFieldStyle}
            id="select-partner-modal"
          />

          <Box sx={{ display: "flex", justifyContent: "center", padding: "12px 16px 12px 16px" }}>
            <Button
              onClick={() => {
                handleClickOpenFilterGroupDialogForm(DialogTypes.CREATE);
              }}
              variant={
                partnersUpdatedValues[CONSTANT.REPORTS.PARTNERS.base].length > 0
                  ? "outlinedFullRoundFullWidth"
                  : "outlinedFullRoundFullWidthDisabled"
              }
              disabled={partnersUpdatedValues[CONSTANT.REPORTS.PARTNERS.base].length === 0}
              id="select-partner-modal-new-group-btn"
            >
              <Typography
                variant="body1Small600"
                color={
                  partnersUpdatedValues[CONSTANT.REPORTS.PARTNERS.base].length > 0
                    ? colors.brandDefault
                    : colors.backgroudDisabled
                }
              >
                + New custom group
              </Typography>
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              padding: "8px 16px 8px 0px",
              justifyContent: "space-between",
              alignItems: "flex-start",
              alignSelf: "stretch",
              borderRadius: "0px 0px 16px 16px",
              background: colors.blueishGrey75,
            }}
          >
            <Button onClick={handleClear} variant="clearAll" id="select-partner-modal-clear-all-btn">
              Clear all
            </Button>
            <Button
              disabled={partnersUpdatedValues[CONSTANT.REPORTS.PARTNERS.base].length < 1}
              onClick={handleApply}
              variant="apply"
              id="select-partner-modal-apply-btn"
            >
              Apply
            </Button>
          </Box>
        </Popper>

        <FilterGroupDialogForm
          title={null}
          open={openDialog}
          closeDialog={() => {
            setOpenDialog(false);
          }}
          dialogActionsType={dialogActionsType}
          filterGroup={groupToEdit}
          enableFilters={false}
          type={CONSTANT.FILTER_GROUP_TYPE.PARTNER}
          id="new-partner-group-modal"
        />
      </div>
    </ClickAwayListener>
  );
}
