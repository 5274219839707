import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  formContainer,
  loginContainer,
  logoContainer,
  mainBackgroundContainer,
  formWrapper,
  campaignReviewSpan,
} from "./styles";
import logo from "../../assets/images/logo-negative.svg";
import award2024 from "../../assets/images/award2024.svg";
import award2023 from "../../assets/images/award2023.svg";
import { getUserMetadataByToken } from "../../auth/auth-api";
import Loader from "../../components/Loader";
import Splashscreen from "../../components/Splashscreen/Splashscreen";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { colors } from "../../theme";
import { CONSTANT } from "../../constants/constants";

const adBlockerErrorMessage =
  "Your ad blocker might be blocking the login process. Please disable it or whitelist IntentIQ site.";

const LoginRedirect: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, getAccessTokenSilently, logout, error } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      console.error("Auth0 Error detected:", error);

      if (error.message && error.message.includes("Failed to fetch")) {
        console.error("Network error or request blocked by client.");
        alert(adBlockerErrorMessage);
      } else {
        if ("error" in error && "error_description" in error) {
          const auth0Error = error as { error: string; error_description: string };
          console.error("Auth0 Error Code:", auth0Error.error);
          console.error("Auth0 Error Description:", auth0Error.error_description);
          if (auth0Error.error_description.includes("is not part of the")) {
            alert("User is not found in the system, please contact administrator.");
          } else {
            alert(`Login Error: ${auth0Error.error_description}`);
          }
        } else {
          alert(`An error occurred during login: ${error.message}`);
        }
      }
      navigate("/login");
      return;
    }

    const getUserMetadataWrapper = async () => {
      try {
        const token = await getAccessTokenSilently();
        await getUserMetadataByToken(token, dispatch, true, navigate);
      } catch (e: any) {
        console.error("Authentication error (catch block):", e);
        alert(adBlockerErrorMessage);
        await logout({ logoutParams: { returnTo: CONSTANT.auth0.logout_uri } });
      }
    };

    if (isAuthenticated) {
      getUserMetadataWrapper();
    }
  }, [isAuthenticated, dispatch, navigate, location.search, logout, error]);

  return (
    <Box sx={loginContainer}>
      <Box sx={formWrapper}>
        <Box sx={{ ...formContainer, display: "flex", flexFlow: "column" }}>
          <Box sx={logoContainer}>
            <Link to="/" onClick={(e) => e.preventDefault()}>
              <img src={logo} alt="logo" />
            </Link>
          </Box>
          <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Box
              sx={{
                height: "200px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Loader color={colors.primaryCta60} cpSize={"80px"} sx={undefined} />
              </Box>
              <Box>
                <Typography variant="h1XLargeWhite800">Please, wait...</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={mainBackgroundContainer}>
        <Splashscreen />
        <Box sx={{ ...campaignReviewSpan, display: "flex", flexDirection: "column" }}>
          <Typography variant="bodyXLargeRegular" sx={{ color: colors.text02 }}>
            monitoring
          </Typography>
          <Typography variant="xxLarge800">Bid Enhancement</Typography>
          <Box sx={{ marginTop: "16px" }}>
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap: "8px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src={award2024}
                  alt="award_1"
                  style={{
                    zIndex: 100,
                  }}
                />
                <img
                  src={award2023}
                  alt="award_2"
                  style={{
                    marginLeft: "-15px",
                    zIndex: 50,
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  padding: "16px",
                  alignItems: "center",
                  gap: "12px",
                  borderRadius: "40px",
                  background: colors.grayBlack,
                }}
              >
                <img
                  src={logo}
                  alt="logo_award"
                  style={{
                    width: "86px",
                    height: "22px",
                  }}
                />
                <Typography
                  variant="body2XSmall"
                  sx={{
                    fontFamily: "Inter",
                    display: "flex",
                    width: "164px",
                    height: "38px",
                    flexDirection: "column",
                    justifyContent: "center",
                    color: colors.mainWhite,
                    fontWeight: 400,
                    fontStyle: "normal",
                  }}
                >
                  Best identity technology 2023 & 2024
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginRedirect;
