import React, { FC, useState, useMemo } from "react";
import { Box, Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Cell } from "recharts";
import { ISession } from "../monitoring/monitoringApi";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import StyledDataGridWrapper from "../components/StyledDataGrid/StyledDataGrid";
import { scaleLinear } from "d3-scale";
import moment from "moment";
import { formatDurationFromSeconds } from "./utils";

interface SessionsTableProps {
  sessions: ISession[];
}
const colorScale = scaleLinear<string>().domain([0, 100]).range(["blue", "red"]);

const SessionsTable: FC<SessionsTableProps> = ({ sessions }) => {
  const [selectedSession, setSelectedSession] = useState<ISession | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const { loggedUser } = useSelector((state: RootState) => state.users);

  const handleRowClick = (params: any) => {
    setSelectedSession(params.row);
    //setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedSession(null);
  };

  const sessionColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "isActive",
        headerName: "Status",
        width: 120,
        renderCell: (params) =>
          params.value ? <Box sx={{ color: "green" }}>Active</Box> : <Box sx={{ color: "red" }}>No</Box>,
      },
      {
        field: "userName",
        headerName: "User Name",
        width: 150,
        valueGetter: (params) => `${params.row.user.firstName} ${params.row.user.lastName}`,
      },
      {
        field: "email",
        headerName: "Email",
        width: 250,
        valueGetter: (params) => params.row.user.email,
      },
      {
        field: "company",
        headerName: "Company",
        width: 150,
        valueGetter: (params) => params.row.account.company,
      },

      {
        field: "startTime",
        headerName: "Start Time",
        width: 200,
        valueFormatter: (params) => moment(params.value).format("DD.MM.YYYY | HH:mm"),
      },

      {
        field: "duration",
        headerName: "Duration",
        width: 150,
        valueGetter: (params) => {
          return formatDurationFromSeconds(params.row.duration);
        },
      },

      { field: "sessionId", headerName: "Session ID", flex: 1.5 },
      { field: "ipAddress", headerName: "IP Address", flex: 1 },
    ],
    []
  );

  const activityFrameColumns: GridColDef[] = useMemo(
    () => [
      { field: "id", headerName: "ID", width: 70 },
      {
        field: "startTime",
        headerName: "Start Time",
        width: 300,
        valueFormatter: (params) => new Date(params.value).toLocaleString(),
      },
      {
        field: "endTime",
        headerName: "End Time",
        width: 300,
        valueFormatter: (params) => new Date(params.value).toLocaleString(),
      },
      { field: "numberOfRequests", headerName: "Requests", width: 250 },
      { field: "inactivityThreshold", headerName: "Inactivity Threshold", width: 200 },
    ],
    []
  );
  const processData = selectedSession?.activityFrames?.map((frame) => {
    const startTime = new Date(frame.startTime);
    const endTime = frame.endTime ? new Date(frame.endTime) : new Date(); // Handles null end time
    const duration = (endTime.getTime() - startTime.getTime()) / 1000 / 60; // Duration in minutes
    const requests = Math.min(frame.numberOfRequests, 100);
    const color = colorScale(requests);
    return {
      ...frame,
      startTime: startTime.toLocaleTimeString(), // For display on X-axis
      duration,
      fill: color,
    };
  });

  return (
    <Box sx={{ width: "100%" }}>
      <StyledDataGridWrapper
        rows={sessions}
        columns={sessionColumns}
        initialState={{
          sorting: {
            sortModel: [{ field: "startTime", sort: "desc" }],
          },
        }}
        onRowClick={handleRowClick}
        getRowId={(row) => row.id}
        //getRowClassName={(params) => (params.row.sessionId === loggedUser.sessionId ? "highlight-session" : "")}
      />

      {/* Modal for Activity Frames */}
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle textAlign={"center"}>
          {`Activity Frames for session ${selectedSession?.sessionId}`}
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedSession && (
            <>
              <Box sx={{ height: 400, marginBottom: 4 }}>
                <StyledDataGridWrapper
                  rows={selectedSession.activityFrames}
                  columns={activityFrameColumns}
                  getRowId={(row) => row.id}
                />
              </Box>

              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={processData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="startTime" />
                  <YAxis label={{ value: "Duration (minutes)", angle: -90, position: "insideLeft" }} />
                  <Tooltip />
                  <Bar dataKey="duration">
                    {processData?.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.fill} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SessionsTable;
