import { Box, Button, Divider, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { colors } from "../theme";
import { useEffect, useState } from "react";
import JSAgentStatsCard from "./JSAgentStatsCard";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { message } from "antd";
import { handleDownload, JSAgentStatsRequestDto, JSAgentTypes, useGetJSAgentStatsMutation } from "./jsAgentApi";

// interface JsAgentVersionSubTypes {
//   [subType: string]: string;
// }
// interface JsAgentVersionTypes {
//   [mainType: string]: JsAgentVersionSubTypes;
// }

const initJsAgentVersionTypes = {
  obfuscated: "Obfuscated",
  sync: "Sync",
  ES6: {
    minified: "Minified",
    unminified: "Unminified",
  },
  ES5: {
    minified: "Minified",
    unminified: "Unminified",
  },
};

const JSAgentDetailsPage: React.FC = () => {
  const [fetchJSAgentStatsData, { data: jsAgentStats, isSuccess }] = useGetJSAgentStatsMutation();
  const partnersList = useSelector((state: RootState) => state.role.partnersList) ?? [];
  const token = useSelector((state: RootState) => state.users.loggedUser.token) ?? "";
  const params = useParams();
  const [downloadResult, setDownloadResult] = useState<{ type: number; message: string } | null>(null);
  const [messageApi, contextHolder] = message.useMessage();

  let agentVersion = Number(params.id);

  useEffect(() => {
    if (partnersList.length) {
      getAgentStatsData();
    }
  }, [partnersList]);

  useEffect(() => {
    if (downloadResult) {
      if (downloadResult.type) {
        messageApi.open({
          type: "success",
          content: downloadResult.message,
        });
      } else {
        messageApi.open({
          type: "error",
          content: downloadResult.message,
        });
      }
    }
    setDownloadResult(null);
  }, [downloadResult]);

  const getAgentStatsData = async () => {
    const partnersIds: number[] = partnersList.map((partner: any) => partner.dummy_id);
    if (agentVersion !== undefined && !isNaN(Number(agentVersion))) {
      const requestDto: JSAgentStatsRequestDto = {
        version: Number(agentVersion),
        partnersIds,
      };
      try {
        await fetchJSAgentStatsData(requestDto).unwrap();
      } catch (error) {
        console.error("Failed to fetch JS Agent stats:", error);
      }
    }
  };

  const handleDownloadJSAgent = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, type: JSAgentTypes) => {
    e.stopPropagation();
    handleDownload({ version: agentVersion, agentType: type }, token, setDownloadResult);
  };

  const gapElements = "10px";
  const statsDataContent = isSuccess && jsAgentStats && jsAgentStats?.length > 0 && (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flexStart",
        marginTop: "20px",
        gap: "20px",
      }}
    >
      <Typography variant="bodyXLarge700">Impressions</Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: gapElements,
        }}
      >
        {jsAgentStats?.map((item) => (
          <JSAgentStatsCard key={item.partnerId} item={item} />
        ))}
      </Box>
    </Box>
  );
  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "16px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "8px" }}>
            <Typography variant="bodyLargeRegular" color={colors.textBaseTertiary}>
              {agentVersion}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                textAlign: "center",
                border: `1px solid ${colors.borderDefault}`,
                borderRadius: "24px",
                justifyContent: "space-between",
                padding: "16px",
              }}
            >
              <Typography variant="bodyLargeRegular" color={colors.text01}>
                {initJsAgentVersionTypes.obfuscated}
              </Typography>
              <Button
                variant="text"
                sx={{
                  color: colors.brandDefault,
                  fontWeight: 700,
                }}
                onClick={(e) => {
                  handleDownloadJSAgent(e, JSAgentTypes.Obfuscated);
                }}
              >
                Download
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "8px" }}>
            <Typography variant="bodyLargeRegular" color={colors.textBaseTertiary}>
              ES6
            </Typography>
            <Box sx={{ border: `1px solid ${colors.borderDefault}`, borderRadius: "24px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "space-between",
                  padding: "16px",
                }}
              >
                <Typography variant="bodyLargeRegular" color={colors.text01}>
                  {initJsAgentVersionTypes.ES6.minified}
                </Typography>
                <Button
                  variant="text"
                  sx={{
                    color: colors.brandDefault,
                    fontWeight: 700,
                  }}
                  onClick={(e) => {
                    handleDownloadJSAgent(e, JSAgentTypes.ES6Minified);
                  }}
                >
                  Download
                </Button>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "space-between",
                  padding: "16px",
                }}
              >
                <Typography variant="bodyLargeRegular" color={colors.text01}>
                  {initJsAgentVersionTypes.ES6.unminified}
                </Typography>
                <Button
                  variant="text"
                  sx={{
                    color: colors.brandDefault,
                    fontWeight: 700,
                  }}
                  onClick={(e) => {
                    handleDownloadJSAgent(e, JSAgentTypes.ES6Unminified);
                  }}
                >
                  Download
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "16px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "8px" }}>
            <Typography variant="bodyLargeRegular" color={colors.textBaseTertiary}>
              {initJsAgentVersionTypes.sync}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                textAlign: "center",
                border: `1px solid ${colors.borderDefault}`,
                borderRadius: "24px",
                justifyContent: "space-between",
                padding: "16px",
              }}
            >
              <Typography variant="bodyLargeRegular" color={colors.text01}>
                {initJsAgentVersionTypes.sync}
              </Typography>
              <Button
                variant="text"
                sx={{
                  color: colors.brandDefault,
                  fontWeight: 700,
                }}
                onClick={(e) => {
                  handleDownloadJSAgent(e, JSAgentTypes.Sync);
                }}
              >
                Download
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "8px" }}>
            <Typography variant="bodyLargeRegular" color={colors.textBaseTertiary}>
              ES5
            </Typography>
            <Box sx={{ border: `1px solid ${colors.borderDefault}`, borderRadius: "24px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "space-between",
                  padding: "16px",
                }}
              >
                <Typography variant="bodyLargeRegular" color={colors.text01}>
                  {initJsAgentVersionTypes.ES5.minified}
                </Typography>
                <Button
                  variant="text"
                  sx={{
                    color: colors.brandDefault,
                    fontWeight: 700,
                  }}
                  onClick={(e) => {
                    handleDownloadJSAgent(e, JSAgentTypes.ES5Minified);
                  }}
                >
                  Download
                </Button>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "space-between",
                  padding: "16px",
                }}
              >
                <Typography variant="bodyLargeRegular" color={colors.text01}>
                  {initJsAgentVersionTypes.ES5.unminified}
                </Typography>
                <Button
                  variant="text"
                  sx={{
                    color: colors.brandDefault,
                    fontWeight: 700,
                  }}
                  onClick={(e) => {
                    handleDownloadJSAgent(e, JSAgentTypes.ES5Unminified);
                  }}
                >
                  Download
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {statsDataContent}
      {contextHolder}
    </Box>
  );
};

export default JSAgentDetailsPage;
