import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AppContainer from "./AppContainer";
import { store } from "./store/store";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import { CONSTANT } from "./constants/constants";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <Auth0Provider
    domain={CONSTANT.auth0.domain}
    clientId={CONSTANT.auth0.clientId}
    cacheLocation="localstorage"
    authorizationParams={{
      redirect_uri: CONSTANT.auth0.redirect_uri,
      audience: CONSTANT.auth0.audience,
      organization: CONSTANT.auth0.organization,
    }}
    useRefreshTokens={true}
  >
    <Provider store={store}>
      <ErrorBoundary>
        <AppContainer />
      </ErrorBoundary>
    </Provider>
  </Auth0Provider>
);
