import { Box } from "@mui/material";
import React, { useState } from "react";
import SegmentControl, { ISegmentControlOption } from "../components/SegmentControl/SegmentControl";
import JSAgentsOverviewPage from "./JSAgentsOverviewPage";

export const jsAgentConfigTypes = {
  agent: { id: 1, name: "Agent" },
  module: { id: 2, name: "Module", isDisabled: true },
  other: { id: 3, name: "Other", isDisabled: true },
};

const jsAgentConfigTypesOptions: ISegmentControlOption[] = Object.values(jsAgentConfigTypes);

const JSAgentsPage: React.FC = () => {
  const [activeOption, setActiveOption] = useState<ISegmentControlOption>(jsAgentConfigTypesOptions[0]);

  return (
    <Box sx={{ marginBottom: "20px" }}>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-start", marginBottom: "20px" }}>
        <SegmentControl
          options={jsAgentConfigTypesOptions}
          value={activeOption}
          onChange={(value: ISegmentControlOption) => {
            setActiveOption(value);
          }}
        />
      </Box>
      {activeOption.id === jsAgentConfigTypes.agent.id && <JSAgentsOverviewPage />}
    </Box>
  );
};

export default JSAgentsPage;
